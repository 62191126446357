import React from 'react';
import { Col, Row } from 'reactstrap';
import Input from '../../components/FormFields/Input';
import { Field } from 'formik';

const UserInfo = () => {
  return (
    <>
      <Row>
        <Col>
        <b className="LabelReplacerLabel">Fornavn</b>
          <Field
            name="firstName"
            type="text"
            component={Input}
            placeholder="Fornavn"
          />
        </Col>
        <Col>
        <b className="LabelReplacerLabel">Efternavn</b>
          <Field
            name="lastName"
            type="text"
            component={Input}
            placeholder="Efternavn"
          />
        </Col>
      </Row>
      <b className="LabelReplacerLabel">E-mail</b>
      <Field
        name="email"
        type="email"
        component={Input}
        placeholder="Email"
      />
              <b className="LabelReplacerLabel">Password</b>
      <Field
        name="password"
        type="password"
        component={Input}
        placeholder="Password"
      />
    </>
  );
};

export default UserInfo;
