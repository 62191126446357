import React from 'react';
import {
  Card,
  Row,
  CardBody,
  FormGroup,
  CardTitle,
  Label,
  Col,
  Input as Checkbox
} from 'reactstrap';
import { Field } from 'formik';
import Input from '../../../../components/FormFields/Input';
import Textarea from '../../../../components/FormFields/Textarea';
import DateInput from '../../../../components/FormFields/DatePicker';

const EventSection = ({
  setFieldValue,
  onChange,
  customerFill,
  customer,
  id,
  ...props
}) => {
  return (
    <Card className="CardEvent">
      {/* {!customer && id && (
        <Col className="d-flex justify-content-end mb-2">
          <FormGroup check>
            <Label check>
              <Checkbox
                type="checkbox"
                name="eventSection"
                checked={customerFill}
                onChange={ev => onChange(ev)}
              />{' '}
              Skal udfyldes af kunde
              <span className="form-check-sign">
                <span className="check" />
              </span>
            </Label>
          </FormGroup>
        </Col>
      )} */}
      <CardBody>
        <Row>
          <Col lg={6} className="ColEvent">
            <CardTitle tag="h3" className="text-left">
              <i className="fas fa-star fa-lg"></i>{' '}
              <strong>{props.title || 'Event'}</strong>
              {!customer && id && (
                <FormGroup check>
                  <Label check>
                    <Checkbox
                      type="checkbox"
                      name="eventSection"
                      checked={customerFill}
                      onChange={ev => onChange(ev)}
                    />{' '}
                    Skal udfyldes af kunde
                    <span className="form-check-sign">
                      <span className="check" />
                    </span>
                  </Label>
                </FormGroup>
              )}
            </CardTitle>
            <Row>
              <Col
                lg={4}
                className="LabelReplacerCol"
                style={{ fontWeight: 'bold' }}
              >
                <b className="LabelReplacerLabel">*Eventtitel:</b>
              </Col>
              <Col>
                <Field
                  label={props.inputTitle || 'Eventtitel'}
                  name="title"
                  type="text"
                  component={Input}
                  placeholder="Titel på event"
                />
              </Col>
            </Row>
            <Row>
              <Col
                lg={4}
                className="LabelReplacerCol"
                style={{ fontWeight: 'bold' }}
              >
                <b className="LabelReplacerLabel">*Dato - Fra:</b>
              </Col>
              <Col lg={8}>
                <Field
                  setFieldValue={setFieldValue}
                  label="Dato - Fra"
                  name="from"
                  component={DateInput}
                  placeholder="Fra"
                />
              </Col>
            </Row>
            <Row>
              <Col
                lg={4}
                className="LabelReplacerCol"
                style={{ fontWeight: 'bold' }}
              >
                <b className="LabelReplacerLabel">*Dato - Til:</b>
              </Col>
              <Col lg={8}>
                <Field
                  setFieldValue={setFieldValue}
                  label="Dato - Til"
                  name="to"
                  component={DateInput}
                  placeholder="Til"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="LabelReplacerCol">
                <b className="LabelReplacerLabel">Antal gæster:</b>
              </Col>
              <Col lg={8}>
                <Field
                  label="Antal gæster"
                  name="numberOfParticipant"
                  component={Input}
                  type="text"
                  placeholder="Oplyst antal"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="LabelReplacerCol">
                <b className="LabelReplacerLabel">Eventtype:</b>
              </Col>
              <Col>
                <Field
                  label="Type"
                  type="text"
                  name="type"
                  component={Input}
                  placeholder="Eks. Privat, Firmafest, Messe, mv."
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} className="ColEventDetails">
            <CardTitle tag="h3" className="eventDetaljerTitle">
              <i className="fas fa-clipboard fa-lg"></i>{' '}
              <strong>Eventdetaljer</strong>
              {!customer && id && (
                <FormGroup check style={{ opacity: '0' }}>
                  I'm invisible
                </FormGroup>
              )}
            </CardTitle>

            <Row>
              {/* <Col lg={4} className="LabelReplacerCol">
                <b className="LabelReplacerLabel">Eventtype:</b>
              </Col> */}
            </Row>
            <Row>
              <Col>
                <Field
                  label=""
                  name="note"
                  type="textarea"
                  component={Textarea}
                  placeholder="Tilføj dine afbestillingskriterier, beskrivelser, pakkelister og meget mere"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default EventSection;
