import React from 'react';
import image from '../../assets/img/AB_404_mand.png';
import GoBackButton from '../../components/Buttons/GoBackButton';
import styles from './styles.module.scss';

const NotFound = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Fejl 404</h1>
      <h2 className={styles.subtitle}>
        Øv! Vi fandt desværre ikke den side du ledet efter
      </h2>
      <div className={styles.wrapper}>
        <GoBackButton text="Send mig til mit panel" link={'/my-panel'} />
        <img className={styles.image} src={image} alt="" />
      </div>
    </div>
  );
};

export default NotFound;
