import * as yup from 'yup';

export const name = yup.string().required('Påkrævet');
export const phone = yup.string().required('Påkrævet');

export const email = yup
  .string()
  .email('Det skal være en gyldig email')
  .required('Påkrævet')
  .nullable();
export const password = yup
  .string()
  .min(6, 'Password skal være over 6 tegn')
  .required('Påkrævet');
export const firstName = yup.string().required('Påkrævet');
export const lastName = yup.string().required('Påkrævet');

export const title = yup
  .string()
  .required('Påkrævet')
  .nullable();
export const date = yup
  .date()
  .required('Påkrævet')
  .nullable();

export const organizerName = yup
  .string()
  .required('Påkrævet')
  .nullable();
