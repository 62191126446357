import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { getToken } from '../helper';

const isProduction = process.env.NODE_ENV === 'production';

export const API_HOST = isProduction
  ? process.env.REACT_APP_API_PATH
  : 'http://localhost:4000';

export const client = new ApolloClient({
  uri: `${API_HOST}/graphql`,
  request: async operation => {
    const TOKEN = getToken();
    operation.setContext({
      headers: TOKEN
        ? {
            authorization: `Bearer ${TOKEN}`
          }
        : {}
    });
  },
  cache: new InMemoryCache({
    addTypename: false
  })
});
