import React, { useState } from 'react';
import {
  Card,
  Row,
  CardBody,
  FormGroup,
  CardTitle,
  Label,
  Col,
  Button,
  Input as Checkbox
} from 'reactstrap';
import { Field } from 'formik';
import Input from '../../../../components/FormFields/Input';
import AutoSuggest from '../../../../components/FormFields/AutoSuggest';
import { NotifyContext } from '../../../../commen/NotifyContext';
import MutationData from '../../../../components/MutationData';
import { CREATE_CONTACT } from '../../../Contacts/Contact';
import PhoneNumberInput from '../../../../components/FormFields/PhoneNumberInput'


const VenueSection = ({
  contactInfo,
  onChange,
  customerFill,
  customer,
  id,
  ...props
}) => {
  const [created, setCreated] = useState(false);
  return (
    <NotifyContext.Consumer>
      {({ showNotify }) => (
        <MutationData mutation={CREATE_CONTACT}>
          {({ mutate, options }) => (
            <Col lg={6}>
              <Card className="CardVenue">
                {/* {!customer && id && (
                <Col className="d-flex justify-content-end mb-2">
                  <FormGroup check>
                    <Label check>
                      <Checkbox
                        type="checkbox"
                        name="venueSection"
                        checked={customerFill}
                        onChange={ev => onChange(ev)}
                      />{' '}
                      Skal udfyldes af kunde
                      <span className="form-check-sign">
                        <span className="check" />
                      </span>
                    </Label>
                  </FormGroup>
                </Col>
              )} */}
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <CardTitle tag="h3" className="text-left">
                        <i className="fas fa-map-marker-alt fa-lg"></i>{' '}
                        <strong>{props.title || 'Venue'}</strong>
                        {!customer && id && (
                          <FormGroup check>
                            <Label check>
                              <Checkbox
                                type="checkbox"
                                name="venueSection"
                                checked={customerFill}
                                onChange={ev => onChange(ev)}
                              />{' '}
                              Skal udfyldes af kunde
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        )}
                      </CardTitle>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel">Navn på venue:</b>
                        </Col>
                        <Col>
                          <Field
                            label="Navn på venue"
                            name="venuePlaceName"
                            type="text"
                            section="venue"
                            component={AutoSuggest}
                            placeholder="Venue's stednavn"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel">Adresse:</b>
                        </Col>
                        <Col>
                          <Field
                            label="Adresse"
                            name="venueAddress"
                            type="text"
                            component={Input}
                            placeholder="Venue's adresse"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel"></b>
                        </Col>
                        <Col lg={3}>
                          <Field
                            label="Postnr."
                            name="venueZipCode"
                            component={Input}
                            placeholder="Postnr."
                          />
                        </Col>
                        <Col lg={5}>
                          <Field
                            label="By"
                            name="venueCity"
                            component={Input}
                            placeholder="By"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel">Kontaktperson:</b>
                        </Col>
                        <Col>
                          <Field
                            label="Kontaktperson"
                            name="venueContactPerson"
                            component={Input}
                            placeholder="Venue kontaktperson"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel">Evt. CVR:</b>
                        </Col>
                        <Col>
                          <Field
                            label="Evt. CVR"
                            name="venueRegisterNumber"
                            component={Input}
                            placeholder="12345678"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel">E-mail:</b>
                        </Col>
                        <Col>
                          <Field
                            label="E-mail"
                            name="venueEmail"
                            component={Input}
                            placeholder="Venue e-mail"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel">Telefon:</b>
                        </Col>
                        <Col>
                          <Field
                            label="Telefon"
                            name="venuePhone"
                            component={PhoneNumberInput}
                            placeholder="12 34 56 78"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={{ size: 6, order: 2, offset: 4 }}
                      md={{ size: 6, order: 2, offset: 4 }}
                      xs={{ size: 6, order: 2, offset: 3 }}
                    >
                      {!customer && !created && (
                        <Button
                          disabled={options.loading}
                          color="link"
                          // style={{ fontWeight: 'normal' }}
                          type="button"
                          onClick={() => {
                            const {
                              venuePlaceName,
                              venueAddress,
                              venueZipCode,
                              venueCity,
                              venueRegisterNumber,
                              venuePhone,
                              venueEmail,
                              venueContactPerson
                            } = contactInfo;
                            if (venuePlaceName === '') {
                              showNotify({
                                message:
                                  'Udfyld Navn på Venue, før kontakten kan oprettets',
                                type: 'danger',
                                icon: 'tim-icons fas fa-times',
                                autoDismiss: 8
                              });
                              return;
                            }
                            mutate({
                              variables: {
                                ContactInput: {
                                  cvr: venueRegisterNumber,
                                  address: venueAddress,
                                  zip: venueZipCode,
                                  city: venueCity,
                                  phone: venuePhone,
                                  email: venueEmail,
                                  name: venueContactPerson,
                                  company: venuePlaceName,
                                  role: ''
                                }
                              }
                            }).then(res => {
                              if (res) {
                                showNotify({
                                  message: 'Kontakt oprettet',
                                  type: 'success',
                                  icon: 'tim-icons fas fa-check'
                                });
                                setCreated(true);
                              }
                            });
                          }}
                        >
                          <i className="tim-icons fas fa-user-plus fa-lg" />{' '}
                          Opret som ny kontakt
                          {options.loading ? (
                            <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
                          ) : (
                            // '+ Opret som ny kontakt'
                            ''
                          )}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
        </MutationData>
      )}
    </NotifyContext.Consumer>
  );
};

export default VenueSection;
