export default `
1. Almene bestemmelser
a. Ved bestilling af serviceydelser og anvendelse af software hos ArtistButler accepterer køber (herefter kaldet Brugeren) nærværende forretningsbetingelser. Accept sker ved afkrydsning under tilmelding.
b. ArtistButler har ret til at ændre i forretningsbetingelserne, under forudsætning af at dette er kommunikeret til Brugeren 14 dage i forvejen. Herefter modtager Brugeren de gældende forretningsbetingelser, hver gang ArtistButler sender faktura ud via e-mail. Det påhviler Brugeren til enhver tid at holde sig ajour med de gældende forretningsbetingelser.

2. Medlemskab og vilkår
a. Fra den dag, Brugeren tilmelder sig ArtistButler’s medlemskab, kan Brugeren ubegrænset prøve softwaren i 30 dage.
b. Ved indtastning af Brugerens kortoplysninger accepteres, at ArtistButler, periodevis, må trække et beløb svarende til det valgte produktmix og periode.
c. Første betalingsperiode starter tidligst 30 dage efter, at Brugeren har oprettet en konto hos ArtistButler, ellers starter første betalingsperiode på datoen, hvor kortoplysninger afgives første gang. Betalingsperioden er forudbetalt.
d. Brugerens medlemskab vil blive fornyet automatisk i sin eksisterende sammensætning af produktmix og periode, indtil Brugeren skriftligt afmelder sig hos vores Kundecenter.
e. Brugerens medlemskab giver Brugeren adgang til at bruge ArtistButler’s software til det antal aftaler, brugere, tillægsmoduler m.v., der er indeholdt i det af Brugeren valgte produktmix.
i. Såfremt Brugeren ændrer sit produktmix, vil evt. nye funktioner være tilgængelige med det samme, mens evt. fravalgte funktioner vil være tilgængelige indtil udgangen af igangværende betalingsperiode.
ii. Evt. tilvalg af nye funktioner vil blive afregnet pro rata for første betalingsperiode, og pengene vil blive trukket sammen med betaling for den efterfølgende betalingsperiode.
iii. Ved udvidelse af medlemskabet kan enkelte funktioner, ydelser og tillægsmoduler være tilknyttet selvstændige betingelser, der skal accepteres i tillæg til disse Vilkår, før de kan anvendes.
 
3. Opsigelse og varighed af medlemskab
a. Brugeren kan til enhver tid opsige sit medlemskab. I så fald vil Brugerens medlemskab ikke blive fornyet, når løbende betalingsperiode udløber.
b. Der er ingen refundering eller fortrydelsesret for igangværende betalingsperioder. Dvs. at et medlemskab, som er indbetalt, ikke refunderes. Hvis en Bruger f.eks. betaler 12 mdr. forud, kan dette beløb ikke refunderes, selv om Brugeren stopper med at benytte ArtistButler’s services midt i perioden.
c. Opsigelse skal ske via Brugerens login eller telefonisk henvendelse til vores Kundecenter, som bliver bekræftet efterfølgende pr. e-mail. Efter opsigelse forpligter ArtistButler sig ikke yderligere over for Brugeren.
d. Ved endt medlemskab er ArtistButler ikke længere forpligtet til at opbevare Brugerens data efter 12 mdr
e. Brugeren kan i ArtistButler’s software ændre sit produktmix og foretage nedgradering af medlemskabet. Nedgraderinger træder i kraft fra den følgende betalingsperiode.
f. ArtistButler kan opsige et medlemskab med et varsel på 3 måneder plus løbende betalingsperiode, eller uden varsel ved Brugerens væsentlige misligholdelse af de Generelle Forretningsbetingelser eller ved Brugerens konkurs eller insolvens.

4. Medlemsskabets omfang og anvendelse
a. Brugeren opnår i overensstemmelse med disse forretningsbetingelser en ikke-eksklusiv adgang til at anvende ArtistButler’s service og produktmix. ArtistButler’s service stilles til rådighed online som "software as a service". Brugeren erhverver ikke ArtistButler’s service eller en kopi eller del heraf og opnår ikke licens til at afvikle ArtistButler’s software, undtagen som ”software as a service”.
b. Et medlemskab kan overdrages til tredjemand, på Brugerens ønske og skriftlige tilkendegivelse herom – f.eks. ved salg af virksomhed. Afholdelse af evt. mellemregning mellem Bruger og tredjemand afholdes af Bruger og er ikke ArtistButler ansvarlig. Et medlemskab med ubetalte fakturaer, forfaldende og ikke forfaldende, kan ikke overdrages.
c. Brugeren indestår for og har det fulde ansvar for de tredjeparter, som Brugeren giver adgang til ArtistButler’s Service, eller som bruger Brugerens login-detaljer.
d. Brugeren skal sikre, at ArtistButler’s service ikke bliver anvendt på en måde, som kan skade ArtistButler’s navn, omdømme eller goodwill, eller som er i strid med relevant lovgivning eller anden regulering.
 
5. Priser og betalingsbetingelser
a. Gældende priser kan til enhver tid findes på ArtistButler’s hjemmeside.
b. ArtistButler forbeholder sig til enhver tid retten til at justere prisen med to ugers varsel.
c. Alle priserne er angivet i danske kroner og er inkl. moms.
d. Betales medlemskabet ikke rettidigt, spærres Brugerens konto efter 7 dage. ArtistButler forsøger trækning på oplyste betalingskort 3 gange i denne periode. Brugeren er selv ansvarlig for, at opdatere kortoplysninger, hvis det aktive kort udskiftes eller spærres.
e. Brugeren accepterer, at fakturaer sendt pr. e-mail til den af Brugeren angivne e-mailadresse skal anses for leverede, når de er afsendt af ArtistButler.
 
6. Ansvarsbegrænsning
 

a. ArtistButler tager forbehold for programmeringsfejl og servernedbrud og kan ikke holdes ansvarlig for direkte eller indirekte tab, der er sket i forbindelse med anvendelse af ArtistButler’s software. ArtistButler er ligeledes ikke ansvarlig for hacker-angreb, vira eller nogen form for force majeure.

 

b. ArtistButler rådgiver ikke i regnskabstekniske eller skattetekniske henseender, men hjælper gerne med tekniske spørgsmål. Det er dog Brugerens eget ansvar at eftertjekke, om dette er korrekt.

 

c. ArtistButler holdes skadesløs for ethvert krav eller tab, der skyldes produktansvar, tab hos tredjeparter eller ansvar over for tredjeparter, i det omfang det hidrører Brugerens brug af ArtistButler’s software.

 

d. ArtistButler beholder backup af alle data i op til 3 måneder. Alle backup-behov herudover er Brugerens eget ansvar.

 

e. Det er Brugerens ansvar at sikre, at indgående og udgående aftalegrundlag, ved brugen af ArtistButler’s software, stemmer overens, og at beløb og moms bliver afregnet korrekt. Ligeledes er det Brugerens ansvar at leve op til gældende moms- og andre skatteregler. Med andre ord er det Brugerens ansvar, at brugen af ArtistButler’s software sker i overensstemmelse med gældende dansk lov.

 

f. ArtistButler er ikke ansvarlig for Brugerens brug af ArtistButler’s API. ArtistButler kan således ikke holdes ansvarlig for rigtigheden, fuldstændigheden, kvaliteten og pålideligheden af informationerne og heller ikke resultaterne, som opnås gennem disse integrationer. Tilsvarende kan ArtistButler ikke holdes ansvarlig for integrationers tilgængelighed, sikkerhed eller funktionalitet, herunder for mulige skader og/eller tab.

 

g. Det er Brugerens ansvar at informere ArtistButler ved ændring af: adresse, telefonnummer, e-mailadresse, CVR-nr.

 

h. ArtistButler tilstræber højst mulig driftsstabilitet, men er uden ansvar for nedbrud eller driftsforstyrrelser, herunder for driftsforstyrrelser forårsaget af faktorer uden for ArtistButler’s kontrol. Herved forstås bl.a. strømsvigt, fejl på udstyr, internetforbindelser, telekommunikationsforbindelser eller lignende.

 

i. I tilfælde af nedbrud eller forstyrrelser tilstræber ArtistButler at genoprette normaldrift hurtigst muligt og uden unødige ophold.

 
 
 
7. Brugerens data
 

a. ArtistButler som dataansvarlig: Som led i Brugerens ’onboarding’ og kontinuerlige drift, samler ArtistButler løbende informationer om Brugeren. Det gælder bl.a. kontaktdata, stamdata, betalingsdata samt data omkring Brugerens brug af ArtistButler’s software. Dette er reguleret i ArtistButler’s Cookie politik.

 

b. ArtistButler som Databehandler: ArtistButler og Brugeren er enige om, at Brugeren er ”Dataansvarlig” for al data, Brugeren, som del af sin drift eller på anden vis, akkumulerer i ArtistButler’s software. Det gælder Aftalegrundlag, Betalingsdata, Kontaktinformationer eller eventuelle Personoplysninger. Brugeren ejer og kan frit disponere over egne data i ArtistButler’s Software. Forhold omkring Brugerens data reguleres i den indgåede Databehandleraftale.

 

c. ArtistButler er ikke forpligtet til at opbevare Brugerens data efter 30 dage fra endt medlemskab. ArtistButler forbeholder sig ret til at slette Brugerens data 90 dage efter medlemskabets ophør, uanset årsagen hertil, og ArtistButler har ingen forpligtelser til at opbevare data efter dette tidspunkt.

 

d. ArtistButler er berettiget til at anvende Brugerens data til statistik og analyse af tjenesten. Dette skal ske i fuldt anonymiseret form.

 

e. ArtistButler er berettiget til at opbevare Brugerens data efter endt medlemskab til statistik og analyse af tjenesten. Dette skal ske i fuldt anonymiseret form.

 

f. ArtistButler kan i særlige tilfælde give tredjepart adgang til Brugerens data, dog kun i overensstemmelse med den relevante databeskyttelseslovgivning og dansk ret. Det kan ske i forbindelse med dom, myndighedskrav, Brugerens konkurs, dødsfald eller lignende.

 
Dataindsamling og Behandlingsaktiviteter
ArtistButler indsamler og behandler løbende personoplysninger i forbindelse med levering af vores produkt til vores kunder og leads.

Indsamlingen af data om ArtistButler’s kunder sker på 3 forskellige stadier:

 

1. Leads: Inden der er etableret et kundeforhold

Dette scenarie omhandler situationen, hvor en potentiel kunde er inde og læse på ArtistButler’s hjemmeside, men endnu ikke er kunde. Det kan f.eks. ske i forbindelse med brugerens download af materiale udarbejdet af ArtistButler. Vi betragter disse som potentielle kunder (leads). Data indsamles for at kunne informere leads om ArtistButler’s produkter i fremtiden.

Hvilke oplysninger indsamles: Almindelige personoplysninger, herunder oplysninger om navn og e-mailadresse, samt eventuel kommunikation via ArtistButler’s chat.

Navn

E-mail

Eventuel kommunikation via ArtistButler’s chat

 

2. Ved indgåelse af et nyt kundeforhold

Dette sker, når en bruger, gennem vores hjemmeside, opretter en konto til en virksomhed. I dette tilfælde sker indsamling for at kunne give Brugeren den bedst mulige support og generelt bedste oplevelse ved brug af produktet.

Hvilke oplysninger indsamles:

Navn

Adresse

Land

E-mail

Telefonnummer

Betalingsoplysninger

Virksomhedens navn

Virksomhedens adresse

Virksomhedens telefonnummer

Virksomhedens CVR-nr.

Virksomhedens branche

Virksomhedens regnskabsår

Virksomhedens bankoplysninger

Anonymiseret data indsamlet via digitale tracking-værktøjer

 

3. Løbende levering til eksisterende kunder

Som en del af den løbende levering af vores produkt er det nødvendigt at registrere kunders brug af produktet. Dette gøres gennem tracking af Brugerens henvendelser til supporten samt Brugerens anvendelse af produktet.

Hvilke oplysninger indsamles:

Betalingshistorik

Ejerforhold

Supporthenvendelser og udviklingsønsker

Nye brugere med adgang til virksomhedskontoen

Navn

E-mail

Evt. databerigelse fra offentlige kilder

Anonymiseret data indsamlet via digitale tracking-værktøjer

 

Formål og grundlag for indsamling

ArtistButler behandler de indsamlede data med forskelligt formål, afhængigt af hvilket indsamlings stadie, data stammer fra. Leads: Inden der er etableret et kundeforhold. Data indsamles for at kunne informere Leads om ArtistButler’s produkter i fremtiden med det overordnede mål at konvertere leadet til en ArtistButler-kunde.

 

1. Ved indgåelse af et nyt kundeforhold

Levere en fyldestgørende onboarding-proces, der giver Brugeren en god forståelse af programmet.

Være i stand til at fakturere Brugeren for brug af produktet.

Udføre fyldestgørende internt regnskab.

Foretage statistisk behandling og forretningsudvikling.

 

2. Løbende levering til eksisterende kunder

Kvalitetsstyring.

Styre vores forhold til kunder.

Udvikle vores forretning og tjenester (f.eks. identificere kundebehov og forbedringer i serviceleverancen).

Foretage statistisk behandling og forretningsudvikling.

Identificere brugeres behov i forhold til nye og eksisterende features, med henblik på at få Brugeren til at bruge dem.

 
Dataindsamling i forhold til Leverandører og samarbejdspartnere
Vi indsamler og behandler personoplysninger om vores leverandører og samarbejdspartnere, herunder personoplysninger om personer ansat hos leverandører og samarbejdspartnere.

Vi behandler alene almindelige oplysninger, herunder kontaktoplysninger.

Vi behandler oplysningerne til brug for kontraktstyring samt for at modtage varer og tjenester fra vores leverandører og samarbejdspartnere. Vores grundlag er at opfylde kontrakten, som den registrerede er part i.

 
Brug af cookies
På hjemmesiden gøres der brug af ”cookies”. En cookie er en lille tekstfil, som gemmes i webbrowseren på din computer, smartphone eller iPad, når du besøger hjemmesiden. Cookies gør det muligt at genkende din computer mv. og samle information om din adfærd på nettet, herunder hvilke sider og funktioner der besøges med din browser, samt sikre at den fungerer rent teknisk. I nogle tilfælde er cookies den eneste måde at få et website til at fungere efter hensigten. En cookie er en passiv fil og kan ikke indsamle oplysninger på din computer, sprede computervirus eller andre skadelige programmer. Den er anonym og indeholder ingen personoplysninger. Cookies anvendes af stort set alle websites.

 
 
 
8. Behandlingssikkerhed
 

Datasikkerhed har højeste prioritet hos ArtistButler. Vi arbejder seriøst og professionelt og med udgangspunkt i internationalt anerkendte sikkerhedsstandarder. Vi gennemfører regelmæssigt interne opfølgninger i forhold til tilstrækkeligheden og efterlevelsen af politikker og foranstaltninger.

ArtistButler forpligter sig til at undgå uautoriseret adgang, offentliggørelse eller anden afvigende behandling af personoplysninger. ArtistButler skal sikre fortroligheden af de personoplysninger, vi behandler, fastholde integriteten af personoplysninger og sikre sin tilgængelighed i overensstemmelse med gældende lovgivning om persondatabeskyttelse.

 
 
 
9. Videregivelse af personoplysninger
 

ArtistButler anvender en række underleverandører for at kunne levere vores produkt. Disse er nødt til at have adgang til kunders personoplysninger.

ArtistButler kan i den forbindelse eksportere Brugeres data til et sted uden for EU. Disse underleverandører er typiske leverandører af cloud-tjenester eller andre IT-hosting-tjenester. ArtistButler er forpligtet til at indgå databehandlingsaftaler, der sikrer brugeres rettigheder i forhold til personoplysninger. Er en underleverandør placeret uden for EU, er ArtistButler forpligtet til at sikre et juridiske grundlag for sådanne internationale overførsler. Her vil ArtistButler støtte sig til Privacy Shield (US) eller ved at gøre brug af EU-modelklausuler.

Offentlige myndigheder eller tredjeparter, som krævet af og i overensstemmelse med gældende lovgivning eller regulering:

ArtistButler kan undertiden blive pålagt at udlevere personoplysninger til offentlige myndigheder eller tredjeparter, hvilket vi udleverer, hvis det er krævet i henhold til gældende lovgivning eller regulering. Dette kan eksempelvis være for at undersøge en påstået forbrydelse, etablere, udøve eller forsvare juridiske rettigheder. ArtistButler vil kun opfylde anmodninger om videregivelse af personoplysninger, hvor vi har pligt til at gøre det.

 
 
 
10. Opbevaringsperiode for personoplysninger
​

ArtistButler sletter dine personoplysninger, når de ikke længere er nødvendige for det formål, de blev indsamlet under.

ArtistButler behandler også data med rent statistiske formål, men i disse tilfælde vil dataene være anonymiserede.

​
 
 
11. Dine rettigheder
​

Registrerede har visse rettigheder, som ArtistButler i rollen som dataansvarlig er forpligtet til at opfylde.

Du har ret til at fravælge modtagelsen af markedsføringskommunikation fra ArtistButler og kan gøre det ved at følge instruktionerne for framelding i den relevante markedsføringskommunikation. Bemærk venligst, at selv om du fravælger at modtage markedsføringskommunikation, kan du stadig modtage administrative meddelelser fra ArtistButler, eksempelvis ordrebekræftelser og meddelelser, som er nødvendige for at administrere din konto eller de tjenester, du anvender.

Brugeren har nedenstående rettigheder:

Den registreredes indsigtsret

Retten til berigtigelse

Retten til sletning (»retten til at blive glemt«)

Retten til at gøre indsigelse mod resultatet af automatiske individuelle afgørelser, herunder profilering

Ønsker Brugeren at gøre brug af sine rettigheder, skal der rettes henvendelse til ArtistButler på e-mail: kontakt@artistbutler.dk

Kunder skal i den forbindelse oplyse:

Konto-ID for den konto, efterspørgslen relaterer sig til

Hvilken ret der ønskes udnyttet

​
 
 
12. Ændringer til denne Erklæring
​

Hvis vi ændrer vores Erklæring om beskyttelse af personlige oplysninger, vil vi publicere den reviderede erklæring her med en opdateret revisionsdato. Vi opfordrer dig til at gennemgå Erklæringen regelmæssigt. Hvis vi foretager væsentlige ændringer af vores Erklæring, som i betydelig grad ændrer vores praksisser for persondatabeskyttelse, kan vi også meddele dette til dig på andre måder, eksempelvis ved at sende en e-mail eller ved at offentliggøre en meddelelse på vores hjemmeside og/eller på sociale medier, inden ændringerne træder i kraft.

Denne Erklæring om beskyttelse af personlige oplysninger blev opdateret den 13. november 2018.

​
 
 
13. Ophavsret
​

a. Ved indgåelse af nærværende forretningsbetingelser tildeles ArtistButler, ift. Brugerens data, tilstrækkelige rettigheder til at kunne operere og leve op til sine forpligtelser ift. Brugeren.

​

b. Der sker ingen overdragelse af immaterielle rettigheder til Brugeren.

​

c. Alt materiale, der forefindes på ArtistButler’s webside og software, tilhører ArtistButler. Det gælder både designs, tekster, funktionalitet og helhedsindtryk. Såfremt du ønsker at citere eller på anden måde omtale ArtistButler, bedes du forudgående anmode ArtistButler om skriftlig tilladelse.

​

d. Alt data og information, der afgives fra ArtistButler’s software, dog med undtagelse af Brugerens data, der er omfattet af Databehandleraftalen, tilhører ArtistButler og må beholdes, så længe det er i overensstemmelse med gældende lovgivning.

​

e. Brugeren indestår for, at det materiale, der uploades, ikke krænker tredjemands rettigheder og ikke indeholder materiale, der kan virke stødende eller er i strid med relevant lovgivning eller anden regulering.

​
 
 
14. Udvikling, ændringer og opdateringer
​

a. ArtistButler er berettiget til at gennemføre alle ændringer, opdateringer, tilføjelser og begrænsninger m.m., som ArtistButler måtte finde relevante eller nødvendige. Sådanne opdateringer, forbedringer og ændringer kan ske med eller uden varsel og kan påvirke services, herunder informationer og data uploadet til eller afgivet af ArtistButler’s software.

​
 
 
15. Salg eller fusion
​

I forbindelse med fusioner og virksomhedsovertagelser eller frasalg af hele eller dele af ArtistButler’s forretning vil den overtagende enhed samt dennes konsulenter få adgang til de beskrevne oplysninger. Dette vil inkludere personoplysninger. I sådanne sager vil de eksterne parter indgå en hemmeligholdelsesaftale med ArtistButler.

​
 
 
16. Tvister
​

a. Disse Vilkår er undergivet dansk ret, og enhver tvist, der udspringer af medlemskabet, herunder disse Vilkår, skal anlægges ved Byretten i Horsens.

​
 
 
17. Gyldighed
​

a. Disse Vilkår er gyldige fra 01. oktober 2019 og erstatter tidligere vilkår.
`;
