import React from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  Col,
  CardBody,
  Row,
  Button,
  CardFooter
} from 'reactstrap';
import { Prompt } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import gql from 'graphql-tag';
import MutationData from 'components/MutationData';
import { GET_USER_INFO } from './index';
import Input from 'components/FormFields/Input';
import './Profile.css';
const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo($UserDetailsInput: UserDetailsInput!) {
    updateUser(user: $UserDetailsInput) {
      email
      firstName
      lastName
      address
      zip
      city
      country
      phone
      companyName
    }
  }
`;

const ProfileInfo = props => {
  return (
    <MutationData mutation={UPDATE_USER_INFO}>
      {({ mutate }) => {
        return (
          <Formik
            initialValues={props.me}
            onSubmit={(values, actions) => {
              const { id, ...UserDetailsInput } = values;
              mutate({
                variables: {
                  UserDetailsInput
                },
                refetchQueries: [
                  {
                    query: GET_USER_INFO
                  }
                ]
              }).then(res => {
                actions.setSubmitting(false);
                props.showNotify({
                  message: 'Din konto er opdateret',
                  type: 'success',
                  icon: 'tim-icons fas fa-check'
                });
                actions.resetForm(res.data.updateUser);
              });
            }}
          >
            {({ isSubmitting, dirty }) => {
              return (
                <Form>
                  <Prompt
                    when={dirty}
                    message={() => {
                      return `Du har ændringer som ikke er gemt. \rTryk "Cancel" og derefter "Gem" for at gemme`;
                    }}
                  />
                  <Col md="12" className="ProfileInfoMainCol">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h3">
                          <i className="fas fa-user-circle fa-lg"></i>{' '}
                          <strong>Profiloplysninger</strong>
                        </CardTitle>
                      </CardHeader>
                      <CardBody className="ProfileInfoCardBody">
                        <Row>
                          <Col lg={4} className="LabelReplacerCol">
                            <b className="LabelReplacerLabel">Virksomhed</b>
                          </Col>
                          <Col>
                            <Field
                              label="Virksomhed"
                              name="companyName"
                              component={Input}
                              placeholder="Firmanavn"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4} className="LabelReplacerCol">
                            <b className="LabelReplacerLabel">Fornavn</b>
                          </Col>
                          <Col>
                            <Field
                              label="Fornavn"
                              name="firstName"
                              type="text"
                              component={Input}
                              placeholder="Fornavn"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4} className="LabelReplacerCol">
                            <b className="LabelReplacerLabel">Efternavn</b>
                          </Col>
                          <Col>
                            <Field
                              label="Efternavn"
                              name="lastName"
                              type="text"
                              component={Input}
                              placeholder="Efternavn"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4} className="LabelReplacerCol">
                            <b className="LabelReplacerLabel">Adresse</b>
                          </Col>
                          <Col>
                            <Field
                              label="Adresse"
                              name="address"
                              type="text"
                              component={Input}
                              placeholder="Adresse"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}></Col>
                          <Col lg={3}>
                            <Field
                              label="Postnr."
                              name="zip"
                              component={Input}
                              placeholder="Postnr."
                            />
                          </Col>
                          <Col lg={5}>
                            <Field
                              label="By"
                              name="city"
                              component={Input}
                              placeholder="By"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4} className="LabelReplacerCol">
                            <b className="LabelReplacerLabel">Telefonummer</b>
                          </Col>
                          <Col>
                            <Field
                              label="Telefonummer"
                              name="phone"
                              component={Input}
                              placeholder="fx. 80808080"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4} className="LabelReplacerCol">
                            <b className="LabelReplacerLabel">E-mail</b>
                          </Col>
                          <Col>
                            <Field
                              label="E-mail"
                              name="email"
                              component={Input}
                              placeholder="fx. mail@email.com"
                            />
                          </Col>
                        </Row>
                        <CardFooter>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <span style={{ marginLeft: '1em' }}>
                              <Button
                                disabled={isSubmitting}
                                color="primary"
                                type="submit"
                              >
                                {isSubmitting ? (
                                  <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
                                ) : (
                                  'Gem'
                                )}
                              </Button>
                            </span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </MutationData>
  );
};

export default ProfileInfo;
