import React from 'react';
import styles from './styles.module.scss';
import MutationData from '../../components/MutationData';
import gql from 'graphql-tag';
import { Button } from 'reactstrap';
import { GET_USER_INFO } from './index';
import { NotifyContext } from '../../commen/NotifyContext';

const REACTIVATE_SUBSCRIPTION = gql`
  mutation ReactivateSubscription($subId: ID) {
    reactivateSubscription(subId: $subId)
  }
`;

const PaymentDisplay = props => {
  const { last4, exp_year, exp_month } = props.stripe.sources.data[0];

  return !props.canceled ? (
    <div className={styles.wrapper}>
      <div
        className={styles.cardNumber}
      >{`Kortnummer: •••• •••• •••• ${last4}`}</div>
      <div className={styles.exp}>{`Udløbsdato: ${
        exp_month.length === 2 ? '' : 0
      }${exp_month}/${exp_year}`}</div>
    </div>
  ) : (
    <MutationData mutation={REACTIVATE_SUBSCRIPTION}>
      {({ mutate, options }) => (
        <NotifyContext.Consumer>
          {({ showNotify }) => (
            <Button
              color="primary"
              type="button"
              onClick={() => {
                mutate({
                  variables: {
                    subId:
                      (props.stripe.subscriptions.data.length > 0 &&
                        props.stripe.subscriptions.data[0].id) ||
                      null
                  },
                  refetchQueries: [
                    {
                      query: GET_USER_INFO
                    }
                  ]
                }).then(() => {
                  showNotify({
                    message: 'Dit abonnement er genaktiveret',
                    type: 'success',
                    icon: 'tim-icons fas fa-check'
                  });
                });
              }}
            >
              {options.loading ? (
                <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
              ) : (
                'Genaktiver'
              )}
            </Button>
          )}
        </NotifyContext.Consumer>
      )}
    </MutationData>
  );
};

export default PaymentDisplay;

// reactivateSubscription
