import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  Col,
  CardBody,
  Button,
  Row
} from 'reactstrap';
import PaymentForm from '../SignUp/Payment';
import PaymentDisplay from './PaymentDisplay';
import PaymentInfo from './PaymentInfo';

const Payment = ({ payments, stripe, canceled, ...props }) => {
  const [updatePayment, setUpdatePayment] = useState(false);

  return (
    <Col md="12">
      <Card>
        <CardHeader>
          <CardTitle tag="h2" className="text-center">
          <i className="fas fa-credit-card"></i> {' '}
            <strong>Betalingsoplysninger</strong>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <PaymentInfo
                canceled={canceled}
                stripe={stripe}
                payments={payments}
              />
            </Col>
            <Col>
              {!updatePayment ? (
                <PaymentDisplay canceled={canceled} stripe={stripe} />
              ) : (
                <PaymentForm
                  setUpdatePayment={setUpdatePayment}
                  updatePayment
                  buttonText={'Opdater oplysninger'}
                />
              )}
            </Col>
          </Row>

          {!canceled && !updatePayment && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <span style={{ marginLeft: '1em' }}>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    if (updatePayment === false) {
                      setUpdatePayment(true);
                    }
                  }}
                >
                  Ændrer oplysninger
                </Button>
              </span>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default Payment;
