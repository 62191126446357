import React from 'react';
import { Formik, Form } from 'formik';
import EventSection from '../Events/Event/Sections/EventSection';
import { Button, Container, Card, CardHeader, CardTitle } from 'reactstrap';
import QueryData from '../../components/QueryData';
import MutationData from '../../components/MutationData';
import gql from 'graphql-tag';
import jwtDecode from 'jwt-decode';
import VenueSection from '../Events/Event/Sections/VenueSection';
import OrganizerSection from '../Events/Event/Sections/OrganizerSection';
import './style.module.scss';
import { NotifyContext } from '../../commen/NotifyContext';

const UPDATE_CUSTOMER_EVENT = gql`
  mutation updateCustomerEvent($id: ID!, $EventInput: EventInput!) {
    updateCustomerEvent(id: $id, event: $EventInput) {
      id
    }
  }
`;

const GET_CUSTOMER_EVENT = gql`
  query getCustomerEvent($id: ID!) {
    getCustomerEvent(id: $id) {
      title
      from
      to
      numberOfParticipant
      type
      note
      venuePlaceName
      venueAddress
      venueZipCode
      venueCity
      venueCountry
      venueRegisterNumber
      venuePhone
      venueEmail
      venueContactPerson
      organizerName
      organizerAddress
      organizerZipCode
      organizerCity
      organizerCountry
      organizerRegisterNumber
      organizerPhone
      organizerEmail
      organizerContactPerson
      status
    }
    user: getCustomerEvent(id: $id) {
      #companyName
      creator {
        firstName
        lastName
      }
    }
  }
`;

const urlParams = new URLSearchParams(window.location.search);
const TOKEN = urlParams.get('token');
const decodeToken = token => {
  return jwtDecode(token);
};

const CustomerFillForm = () => {
  const id = decodeToken(TOKEN).eventId;
  const sections = decodeToken(TOKEN).sections;
  const d = new Date();
  d.setUTCSeconds(decodeToken(TOKEN).exp);
  const exp = d < new Date() ? true : false;

  return (
    <NotifyContext.Consumer>
      {({ showNotify }) => (
        <QueryData query={GET_CUSTOMER_EVENT} variables={{ id }}>
          {({ getCustomerEvent, user }) => {
            const sendFrom =
              user.companyName === ''
                ? `${user.creator.firstName} ${user.creator.lastName}`
                : user.companyName;
            return (
              <div style={{ marginTop: '-10%' }}>
                <header className="text-center">
                  <h1>Informationsdetaljer på dit arrangement </h1>
                  <p className="lead">{`Sendt fra ${sendFrom}`}</p>
                </header>
                <MutationData
                  mutation={UPDATE_CUSTOMER_EVENT}
                  variables={{ id }}
                >
                  {({ mutate }) => (
                    <Container>
                      {!exp ? (
                        <Formik
                          initialValues={getCustomerEvent}
                          onSubmit={(values, actions) => {
                            if (getCustomerEvent.status !== 'PENDING_USER') {
                              mutate({
                                variables: { id, EventInput: { ...values } }
                              }).then(res => {
                                if (res) {
                                  showNotify({
                                    message: `Dit event's information er sendt til Artisten`,
                                    type: 'success',
                                    icon: 'tim-icons fas fa-check'
                                  });
                                }
                                actions.setSubmitting(false);
                              });
                            }
                          }}
                        >
                          {({ setFieldValue, values, isSubmitting }) => {
                            return (
                              <Form>
                                <Card>
                                  <CardHeader>
                                    <CardTitle tag="h2" className="text-center">
                                      {values.title}
                                    </CardTitle>
                                  </CardHeader>
                                </Card>
                                {sections.eventSection && (
                                  <EventSection
                                    title="Dit arrangement"
                                    inputTitle="Arrangementsnavn"
                                    customer
                                    setFieldValue={setFieldValue}
                                  />
                                )}
                                {sections.venueSection && (
                                  <VenueSection
                                    title="Venue (Lokation)"
                                    customer
                                    setFieldValue={setFieldValue}
                                  />
                                )}
                                {sections.organizerSection && (
                                  <OrganizerSection
                                    title="Dine oplysninger"
                                    inputTitle="Navn eller virksomhed"
                                    customer
                                    setFieldValue={setFieldValue}
                                  />
                                )}

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginBottom: '1em'
                                  }}
                                >
                                  <Button color="primary" type="submit">
                                    {isSubmitting ? (
                                      <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
                                    ) : (
                                      'Send information'
                                    )}
                                  </Button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      ) : (
                        <p style={{ textAlign: 'center' }}>
                          Kontakt Artisten for at lave ændringer i dit
                          arrangement
                        </p>
                      )}
                    </Container>
                  )}
                </MutationData>
              </div>
            );
          }}
        </QueryData>
      )}
    </NotifyContext.Consumer>
  );
};

export default CustomerFillForm;
