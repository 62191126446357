import React from 'react';
import { FormGroup, FormText } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
require('moment/locale/da');
moment.suppressDeprecationWarnings = true;
const DateInput = ({ form: { touched, errors }, field, ...props }) => {
  const name = field.name;
  const { placeholder, setFieldValue, timeSchedule } = props;
  const localTime = moment.utc(field.value).toDate();
  return (
    <FormGroup
      className={
        touched[name] && errors[name]
          ? 'has-danger'
          : touched[name] && !errors[name]
          ? 'has-success'
          : ''
      }
    >
      <Datetime
        {...field}
        value={field.value ? moment(localTime, 'DD-MM-YYYY HH:mm') : ''}
        onChange={e => {
          setFieldValue(name, e);
        }}
        dateFormat={timeSchedule ? false : 'DD MMM YYYY'}
        inputProps={{
          className: 'form-control',
          placeholder,
          name,
          id: name,
          autoComplete: 'off'
        }}
        date
      />
      <FormText color="danger">
        {touched[name] && errors[name] && (
          <div className="error">{errors[name]}</div>
        )}
      </FormText>
    </FormGroup>
  );
};

export default DateInput;
