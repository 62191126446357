export const INIT_VALUES = {
  cvr: '',
  company: '',
  address: '',
  zip: '',
  city: '',
  phone: '',
  email: '',
  name: '',
  role: ''
};
