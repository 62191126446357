import React from 'react';
import { Label, FormGroup, Input } from 'reactstrap';

const Textarea = ({ form: { touched, errors }, field, ...props }) => {
  const name = field.name;
  const { placeholder, type, label } = props;
  const wordCount = (field.value && field.value.length) || 0;
  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Input
        {...field}
        value={props.value ? props.value : field.value || ''}
        className="textarea"
        type={type}
        id={name}
        placeholder={placeholder}
        maxLength={1000}
        rows={50}
      />
      <p
        style={{
          textAlign: 'right',
          color:
            wordCount > 800 ? '#e74c3c' : wordCount > 500 ? '#f1c40f' : '#333'
        }}
      >
        Tegn: {wordCount}/1000
      </p>
    </FormGroup>
  );
};

export default Textarea;
