import React, { useState } from 'react';
import {
  Card,
  Row,
  CardBody,
  FormGroup,
  CardTitle,
  Label,
  Col,
  Button,
  Input as Checkbox
} from 'reactstrap';
import { Field } from 'formik';
import Input from '../../../../components/FormFields/Input';
import { NotifyContext } from '../../../../commen/NotifyContext';

import AutoSuggest from '../../../../components/FormFields/AutoSuggest';
import { CREATE_CONTACT } from '../../../Contacts/Contact';
import MutationData from '../../../../components/MutationData';

import PhoneNumberInput from '../../../../components/FormFields/PhoneNumberInput'



const OrganizerSection = ({
  customerFill,
  onChange,
  customer,
  id,
  contactInfo,
  ...props
}) => {
  const [created, setCreated] = useState(false);
  return (
    <NotifyContext.Consumer>
      {({ showNotify }) => (
        <MutationData mutation={CREATE_CONTACT}>
          {({ mutate, options }) => (
            <Col lg={6}>
              <Card className="CardOrganizer">
                {/* {!customer && id && (
                <Col className="d-flex justify-content-end mb-2">
                  <FormGroup check>
                    <Label check>
                      <Checkbox
                        type="checkbox"
                        name="organizerSection"
                        checked={customerFill}
                        onChange={ev => onChange(ev)}
                      />{' '}
                      Skal udfyldes af kunde
                      <span className="form-check-sign">
                        <span className="check" />
                      </span>
                    </Label>
                  </FormGroup>
                </Col>
              )} */}
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <CardTitle tag="h3" className="text-left">
                        <i className="fas fa-user fa-lg" />{' '}
                        <strong>{props.title || 'Arrangør'}</strong>
                        {!customer && id && (
                          <FormGroup check>
                            <Label check>
                              <Checkbox
                                type="checkbox"
                                name="organizerSection"
                                checked={customerFill}
                                onChange={ev => onChange(ev)}
                              />{' '}
                              Skal udfyldes af kunde
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        )}
                      </CardTitle>

                      <Row>
                        <Col
                          lg={4}
                          className="LabelReplacerCol"
                          style={{ fontWeight: 'bold' }}
                        >
                          <b className="LabelReplacerLabel">*Arrangørnavn:</b>
                        </Col>
                        <Col>
                          <Field
                            label={props.inputTitle || 'Arrangørnavn'}
                            name="organizerName"
                            type="text"
                            section="organizer"
                            component={AutoSuggest}
                            placeholder="Navn på arrangør (din kunde)"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel">Adresse:</b>
                        </Col>
                        <Col>
                          <Field
                            label="Adresse"
                            name="organizerAddress"
                            type="text"
                            component={Input}
                            placeholder="Arrangør's adresse"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel"></b>
                        </Col>
                        <Col lg={3}>
                          <Field
                            label=""
                            name="organizerZipCode"
                            component={Input}
                            placeholder="Postnr."
                          />
                        </Col>
                        <Col lg={5}>
                          <Field
                            label=""
                            name="organizerCity"
                            component={Input}
                            placeholder="By"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel">Kontaktperson:</b>
                        </Col>
                        <Col>
                          <Field
                            label="Kontaktperson"
                            name="organizerContactPerson"
                            component={Input}
                            placeholder="Kontaktperson"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel">Evt. CVR:</b>
                        </Col>
                        <Col>
                          <Field
                            label="Evt. CVR"
                            name="organizerRegisterNumber"
                            component={Input}
                            placeholder="12345678"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          className="LabelReplacerCol"
                          style={{ fontWeight: 'bold' }}
                        >
                          <b className="LabelReplacerLabel">*E-mail:</b>
                        </Col>
                        <Col>
                          <Field
                            label="E-mail"
                            name="organizerEmail"
                            component={Input}
                            placeholder="Arrangør@email.dk"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={4} className="LabelReplacerCol">
                          <b className="LabelReplacerLabel">Telefon:</b>
                        </Col>
                        <Col>
                          <Field
                            label="Telefon"
                            name="organizerPhone"
                            component={PhoneNumberInput}
                            placeholder="12 34 56 78"
                          />
                        </Col>
                      </Row>

                      {/* <CardTitle tag="h3" className="text-center">
                      <strong>&nbsp;</strong>
                    </CardTitle> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={{ size: 6, order: 2, offset: 4 }}
                      md={{ size: 6, order: 2, offset: 4 }}
                      xs={{ size: 6, order: 2, offset: 3 }}
                    >
                      {!customer && !created && (
                        <Button
                          disabled={options.loading}
                          color="link"
                          type="button"
                          onClick={() => {
                            const {
                              organizerName,
                              organizerAddress,
                              organizerZipCode,
                              organizerCity,
                              organizerRegisterNumber,
                              organizerPhone,
                              organizerEmail,
                              organizerContactPerson
                            } = contactInfo;
                            if (organizerName === '') {
                              showNotify({
                                message:
                                  'Udfyld navn på arrangør, før kontakten kan oprettets',
                                type: 'danger',
                                icon: 'tim-icons fas fa-times',
                                autoDismiss: 8
                              });
                              return;
                            }
                            mutate({
                              variables: {
                                ContactInput: {
                                  cvr: organizerRegisterNumber,
                                  address: organizerAddress,
                                  zip: organizerZipCode,
                                  city: organizerCity,
                                  phone: organizerPhone,
                                  email: organizerEmail,
                                  name: organizerContactPerson,
                                  company: organizerName,
                                  role: ''
                                }
                              }
                            }).then(res => {
                              if (res) {
                                showNotify({
                                  message: 'Kontakt oprettet',
                                  type: 'success',
                                  icon: 'tim-icons fas fa-check'
                                });
                                setCreated(true);
                              }
                            });
                          }}
                        >
                          <i className="tim-icons fas fa-user-plus fa-lg" />{' '}
                          Opret som ny kontakt
                          {options.loading ? (
                            <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
                          ) : (
                            // 'Opret som ny kontakt'
                            ''
                          )}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
        </MutationData>
      )}
    </NotifyContext.Consumer>
  );
};

export default OrganizerSection;
