import React, { useState } from 'react';
import { Input, FormGroup, FormText } from 'reactstrap';
import { fetchData } from './fetchData';
import styles from './styles.module.scss';
import { setSuggestContact } from './setSuggestContact';

const AutoSuggest = ({
  form: { touched, errors, values, setValues },
  field,
  section,
  contactPersonsIdx,
  arrayHelpers,
  ...props
}) => {
  const name = field.name;
  const { placeholder, type } = props;
  const [suggests, setSuggests] = useState(null);
  return (
    <FormGroup
      className={
        touched[name] && errors[name]
          ? 'has-danger'
          : touched[name] && !errors[name] && field.value
          ? 'has-success'
          : ''
      }
    >
      {/* <Label for={name}>{label ? label : placeholder}</Label> */}
      <Input
        {...field}
        onChange={async ev => {
          const { value } = ev.target;
          field.onChange(ev);
          if (value.length >= 1) {
            const { data } = await fetchData(value);
            setSuggests(data.suggestContacts);
          } else {
            setSuggests(null);
          }
        }}
        onBlur={ev => {
          field.onBlur(ev);
          setSuggests(null);
        }}
        value={props.value ? props.value : field.value || ''}
        autoComplete="off"
        type={type}
        id={name}
        placeholder={placeholder}
      />

      <FormText color="danger">
        {touched[name] && errors[name] && (
          <div className="error">{errors[name]}</div>
        )}
      </FormText>

      {suggests && (
        <ul className={styles.list}>
          {suggests.map(suggest => {
            return (
              <li
                className={styles.listItem}
                onMouseDown={() => {
                  setSuggestContact(
                    suggest,
                    section,
                    setValues,
                    values,
                    contactPersonsIdx,
                    arrayHelpers && arrayHelpers.replace
                  );
                  setSuggests(null);
                }}
                key={suggest.id}
              >
                {suggest.company !== ''
                  ? `${suggest.name} (${suggest.company})`
                  : suggest.name}
              </li>
            );
          })}
        </ul>
      )}
    </FormGroup>
  );
};

export default AutoSuggest;
