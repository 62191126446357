import React, { Component } from 'react';
import Navbar from './components/Navbar/Navbar.jsx';
import Sidebar from './components/Sidebar/Sidebar.jsx';
import routes from './routes.js';
import Routes from './commen/Routes.js';
import { activeColor } from './App';
import { NotificationAlertRoot } from './commen/NotifyContext.js';
import ErrorBoundary from './components/ErrorBoundary.js';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user || {}
    };
  }

  toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };
  getActiveRoute = routes => {
    let activeRoute = 'Artistbutler';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.pathname.indexOf(routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  render() {
    const { firstName, lastName } = this.state.user;
    return (
      <>
        <NotificationAlertRoot />
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={routes}
            activeColor={activeColor}
            user={{
              innerLink: '/profile',
              text: `${firstName} ${lastName}`,
              imgSrc: ''
            }}
            closeSidebar={this.toggleSidebar}
          />
          <div className="main-panel" ref="mainPanel" data={activeColor}>
            <Navbar
              {...this.props}
              onClick={this.logoutHandler}
              // handleMiniClick={this.handleMiniClick}
              brandText={this.getActiveRoute(routes)}
              sidebarOpened={this.state.sidebarOpened}
              toggleSidebar={this.toggleSidebar}
            />

            <div className="content">
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
