import React from 'react';
import { Query } from 'react-apollo';
import Spinner from './UI/Spinner';
import { NotifyContext } from '../commen/NotifyContext';

const QueryData = ({ query, variables, children }) => {
  return (
    <NotifyContext.Consumer>
      {({ showNotify }) => {
        return (
          <Query query={query} variables={variables}>
            {({ data, error, loading, refetch }) => {
              if (error) {
                showNotify({
                  place: 'tc',
                  message: 'Der skete en fejl',
                  type: 'error',
                  icon: 'tim-icon fas fa-times',
                  autoDismiss: 10,
                  closeButton: false
                });
                if (process.env.REACT_APP_ENV === 'dev') {
                  console.log(error.message);
                }
                return;
              }
              if (loading) return <Spinner />;
              return children({
                ...data,
                options: {
                  refetch
                }
              });
            }}
          </Query>
        );
      }}
    </NotifyContext.Consumer>
  );
};

export default QueryData;
