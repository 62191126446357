import React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from 'react-stripe-elements';
import styles from './styles.module.scss';

const Card = () => {
  const classes = {
    base: styles.base,
    focus: styles.focus,
    complete: styles.complete,
    invalid: styles.invalid
  };
  return (
    <>
      <div className={styles.inputGroup}>
        <CardNumberElement classes={classes} />
      </div>
      <div className={styles.inputGroup}>
        <CardExpiryElement classes={classes} />
      </div>
      <div className={styles.inputGroup}>
        <CardCvcElement classes={classes} />
      </div>
    </>
  );
};

export default Card;
