import React from 'react';
import {
  Input,
  FormGroup,
  FormText,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

const InputField = ({ form: { touched, errors }, field, ...props }) => {
  const name = field.name;
  const { placeholder, type, icon } = props;
  return (
    <FormGroup
      className={
        touched[name] && errors[name]
          ? 'has-danger'
          : touched[name] && !errors[name] && field.value
          ? 'has-success'
          : ''
      }
    >
      {icon ? (
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className={icon} />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            {...field}
            style={{ ...(props.style || {}) }}
            // autoComplete="off"
            type={type}
            id={name}
            placeholder={placeholder}
          />
        </InputGroup>
      ) : (
        <Input
          {...field}
          value={props.value ? props.value : field.value || ''}
          // autoComplete="off"
          type={type}
          id={name}
          placeholder={placeholder}
        />
      )}

      <FormText color="danger">
        {touched[name] && errors[name] && (
          <div className="error">{errors[name]}</div>
        )}
      </FormText>
    </FormGroup>
  );
};

export default InputField;
