import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const Sweetalert = ({ title, hideAlert, text, type, ...props }) => {
  return (
    <SweetAlert
      type={type}
      style={{ display: 'block', marginTop: '-200px' }}
      title={title}
      onConfirm={hideAlert}
      onCancel={hideAlert}
      confirmBtnBsStyle="primary"
      {...props}
    >
      {text}
    </SweetAlert>
  );
};

export default Sweetalert;
