import React from 'react';
import ReactTable from 'react-table';
import { Row, Col } from 'reactstrap';

const ReactTables = ({ noDataText, ...props }) => {
  return (
    <>
      <div className="content">
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <ReactTable
              //defaultPageSize={10}
              pageSize={props.data.length}
              showPagination={false}
              className="-highlight"
              minRows={0}
              // minRows={19}
              previousText={'Forrige'}
              nextText={'Næste'}
              // loadingText: 'Henter...',
              noDataText={noDataText || 'Ingen data oprettet endnu'}
              pageText={'Side'}
              ofText={'af'}
              rowsText={'rækker vises'}
              sortable
              {...props}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReactTables;
