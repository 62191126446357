import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { setToken } from '../../helper';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Col
} from 'reactstrap';
import { ApolloConsumer } from 'react-apollo';
import { Formik, Form, Field } from 'formik';
import gql from 'graphql-tag';
import loginSchema from '../../validationSchema/loginSchema';
import Input from '../../components/FormFields/Input';
import { ME } from '../../App';
import MutationData from 'components/MutationData';
import { AppContext } from 'commen/AppContext';
import loginBG from 'assets/img/loginBG.jpg';

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
  }
  componentDidMount() {
    document.body.classList.toggle('login-page');
  }
  componentWillUnmount() {
    document.body.classList.toggle('login-page');
  }

  render() {
    return (
      <AppContext.Consumer>
        {({ setUser }) => (
          <ApolloConsumer>
            {client => (
              <MutationData mutation={LOGIN}>
                {({ mutate }) => {
                  return (
                    <>
                      <div
                        style={{
                          backgroundImage: `url(${loginBG})`,
                          width: '100vw',
                          height: '100vh',
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover'
                        }}
                      ></div>
                      <Container style={{ paddingTop: '7%' }}>
                        <Col className="ml-auto mr-auto" lg="5">
                          <Card
                            className="card-login card-white"
                            style={{
                              boxShadow: 'rgb(51, 51, 51) 1px 3px 8px 0px'
                            }}
                          >
                            <CardHeader className="text-center">
                              <img
                                alt="..."
                                src={require('assets/img/AB_logo.png')}
                                style={{
                                  width: '150px',
                                  marginTop: '15px',
                                  position: 'relative'
                                }}
                              />
                            </CardHeader>
                            <CardBody>
                              <CardTitle
                                tag="h2"
                                className="text-center"
                                style={{
                                  fontWeight: 'Lighter',
                                  fontFamily: 'Roboto',
                                  marginTop: '30px'
                                }}
                              >
                                Log Ind
                              </CardTitle>
                              <Formik
                                initialValues={this.state}
                                validationSchema={loginSchema}
                                onSubmit={(values, actions) => {
                                  mutate({
                                    variables: {
                                      email: values.email.toLowerCase(),
                                      password: values.password
                                    }
                                  })
                                    .then(({ data: { login } }) => {
                                      actions.setSubmitting(false);
                                      setToken(login);
                                      client
                                        .query({
                                          query: ME,
                                          errorPolicy: 'all'
                                        })
                                        .then(res => {
                                          if (res && res.data) {
                                            setUser(res.data);
                                          }
                                        })
                                        .catch(err => {
                                          if (
                                            process.env.REACT_APP_ENV === 'dev'
                                          ) {
                                            console.log(err);
                                          }
                                        });
                                    })
                                    .catch(() => {});
                                  actions.setSubmitting(false);
                                }}
                                validateOnChange={false}
                                render={({ isSubmitting }) => {
                                  return (
                                    <Form>
                                      <b className="LabelReplacerLabel">
                                        E-mail
                                      </b>
                                      <Field
                                        style={{ height: '39px' }}
                                        icon={'tim-icons fas fa-envelope'}
                                        name="email"
                                        type="email"
                                        component={Input}
                                        placeholder="Email"
                                      />
                                      <b className="LabelReplacerLabel">
                                        Password
                                      </b>
                                      <Field
                                        style={{ height: '39px' }}
                                        icon={'tim-icons fas fa-lock'}
                                        name="password"
                                        type="password"
                                        component={Input}
                                        placeholder="Password"
                                      />

                                      {this.state.errorMsg && (
                                        <span className="text-danger">
                                          {this.state.errorMsg}
                                        </span>
                                      )}

                                      <Button
                                        type="submit"
                                        block
                                        className="mb-3"
                                        color="primary"
                                        size="lg"
                                      >
                                        {isSubmitting ? (
                                          <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
                                        ) : (
                                          <i className="tim-icons fas fa-fingerprint" />
                                        )}{' '}
                                        Log ind
                                      </Button>
                                      <div
                                        style={{
                                          margin: '1em 0',
                                          width: '100%',
                                          textAlign: 'center'
                                        }}
                                      >
                                        <p>Eller</p>
                                      </div>
                                      <Link
                                        to="/sign-up"
                                        className="btn btn-primary btn-lg btn-block"
                                      >
                                        <i className="tim-icons fas fa-user-plus" />{' '}
                                        Opret konto
                                      </Link>
                                    </Form>
                                  );
                                }}
                              />
                            </CardBody>
                            <CardFooter>
                              <div className="pull-right">
                                <h6>
                                  <Link
                                    to="/resetPassword"
                                    className="link footer-link"
                                  >
                                    Har du glemt dit password?
                                  </Link>
                                </h6>
                              </div>
                            </CardFooter>
                          </Card>
                        </Col>
                      </Container>
                    </>
                  );
                }}
              </MutationData>
            )}
          </ApolloConsumer>
        )}
      </AppContext.Consumer>
    );
  }
}

export default Login;
