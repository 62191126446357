export function setToken(token) {
  return localStorage.setItem('token', token);
}

export function clearToken() {
  return localStorage.removeItem('token');
}

export function getToken() {
  return localStorage.getItem('token');
}

export async function parseResponse(response) {
  if (response.status >= 200 && response.status < 300) {
    return await response.json();
  } else {
    const error = new Error(response.statusText);
    throw error;
  }
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
}

function expensesAmountSum(expenses) {
  return expenses
    .map(ex => Number(ex.amount))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

export const calcEarnings = (price = 0, expenses = [], vat = 0) => {
  if (isNaN(price)) {
    return 'NaN';
  }
  return numberWithSeparators(price - expensesAmountSum(expenses));
};

export const calcPrice = (price = 0, expenses = [], vat = 0) => {
  if (isNaN(price)) {
    return 'NaN';
  }
  return numberWithSeparators(price + vat);
};

export function hasValue(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return true;
  }

  return false;
}

export function mapErrorsKeysToUser(obj, fn) {
  const fields = Object.keys(obj).map(key => {
    switch (key) {
      case 'title':
        return 'Titel';
      case 'organizerEmail':
        return 'Arrangør E-mail';
      case 'organizerName':
        return 'Arrangør navn';
      case 'from':
        return 'Dato - Fra';
      case 'to':
        return 'Dato - Til';
      default:
        return null;
    }
  });
  return fn(fields);
}

export function numberWithSeparators(number = 0) {
  if(number === null){
    return '0'
  }
  if (isNaN(number)) {
    return 'NaN';
  } else {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
