import React from 'react';
import { format } from 'date-fns';
import styles from './styles.module.scss';
import { numberWithSeparators } from '../../helper';

function displayNextPayment(data) {
  const canceled = !data;
  const active = data && data;
  // console.log(active);

  if (canceled) {
    return 'Abonent udløbet';
  }
  if (active.cancel_at_period_end) {
    return `Abonent slutter: ${format(
      new Date(active.current_period_end * 1000),
      'dd/MM/yyyy'
    )}`;
  }
  return `Næste trækning: ${format(
    new Date(active.current_period_end * 1000),
    'dd/MM/yyyy'
  )}`;
}

const PaymentInfo = props => {
  return (
    <>
      <div
        className={`${styles.next} ${props.canceled ? styles.canceled : ''}`}
      >
        {displayNextPayment(props.stripe.subscriptions.data[0])}
      </div>
      <div className={styles.paymentsWrapper}>
        <h2>Mine trækninger</h2>
        <div style={{ maxHeight: '10em', overflowY: 'auto' }}>
          {props.payments.data.map((payInfo, idx) => {
            return (
              <React.Fragment key={idx}>
                <div className={styles.payments}>
                  <span>
                    {format(new Date(payInfo.created * 1000), 'dd-MM')}
                  </span>
                  <span>{payInfo.description}</span>
                  <span>{numberWithSeparators(payInfo.amount / 100)} kr,-</span>
                </div>
                <hr />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PaymentInfo;
