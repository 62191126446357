import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';

const GoBackButton = props => {
  return (
    <Button onClick={() => props.history.goBack()} color="link">
      {props.text}
    </Button>
  );
};

export default withRouter(GoBackButton);
