import React, { Component } from 'react';
import {
  Col,
  Card,
  Button,
  Container,
  CardHeader,
  CardTitle,
  CardBody
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import registerSchema from '../../validationSchema/registerSchema';
import UserInfo from './UserInfo';
import Payment from './Payment';
import signupBG from 'assets/img/signupBG.jpg';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },
      step: 'userInfo'
    };
  }

  componentDidMount() {
    document.body.classList.toggle('login-page');
  }
  componentWillUnmount() {
    document.body.classList.toggle('login-page');
  }
  render() {
    return (
      <>
        <div
          style={{
            backgroundImage: `url(${signupBG})`,
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            top: '0',
            left: '0',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        ></div>
        <Container style={{ paddingTop: '7%' }}>
          <Col className="ml-auto mr-auto" lg="5">
            <Card
              className="card-login card-white"
              style={{
                boxShadow: 'rgb(51, 51, 51) 1px 3px 8px 0px'
              }}
            >
              <CardHeader className="text-center">
                <img
                  alt="..."
                  src={require('assets/img/AB_logo.png')}
                  style={{
                    width: '150px',
                    marginTop: '15px',
                    position: 'relative'
                  }}
                />
              </CardHeader>
              <CardBody>
                <CardTitle
                  tag="h2"
                  className="text-center"
                  style={{
                    fontWeight: 'Lighter',
                    fontFamily: 'Roboto',
                    marginTop: '30px'
                  }}
                >
                  {this.state.step === 'userInfo'
                    ? 'Opret Bruger'
                    : 'Start prøveperiode'}
                </CardTitle>
                {this.state.step === 'userInfo' && (
                  <Formik
                    initialValues={this.state.userInfo}
                    validationSchema={registerSchema}
                    onSubmit={(values, actions) => {
                      this.setState({ step: 'payment' });
                      actions.setSubmitting(false);
                    }}
                    validateOnChange={false}
                    render={({ isSubmitting, values, handleSubmit }) => {
                      return (
                        <Form>
                          <UserInfo />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'flex-end'
                            }}
                          >
                            <Link to="/" className="link footer-link">
                              <Button
                                className={'btn-primary'}
                                style={{
                                  paddingRight: '20px',
                                  paddingLeft: '20px'
                                }}
                              >
                                {/* <i
                                  class="fas fa-arrow-circle-left"
                                  style={{ marginRight: '8px' }}
                                ></i> */}
                                Tilbage
                              </Button>
                            </Link>

                            <Button
                              className={'btn-primary'}
                              style={{
                                paddingLeft: '20px',
                                paddingRight: '20px'
                              }}
                              type="button"
                              onClick={() => {
                                this.setState(
                                  {
                                    userInfo: values
                                  },
                                  () => handleSubmit()
                                );
                              }}
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
                              ) : (
                                'Næste'
                              )}
                              {/* <i
                                class="fas fa-arrow-circle-right"
                                style={{
                                  marginLeft: '8px'
                                }}
                              ></i> */}
                            </Button>
                          </div>
                        </Form>
                      );
                    }}
                  />
                )}
                {this.state.step === 'payment' && (
                  <Payment user={this.state.userInfo} />
                )}
              </CardBody>
            </Card>
          </Col>
        </Container>
      </>
    );
  }
}

export default SignUp;
