import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from '../views/NotFound';

const MyPanel = React.lazy(() => import('views/MyPanel/'));
const Events = React.lazy(() => import('views/Events/'));
const Event = React.lazy(() => import('views/Events/Event'));
const Contacts = React.lazy(() => import('views/Contacts/'));
const Contact = React.lazy(() => import('views/Contacts/Contact'));
const Profile = React.lazy(() => import('views/Profile/'));
const idRegex = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
const Routes = () => {
  return (
    <React.Suspense fallback={<p />}>
      <Switch primary={false}>
        <Route path="/my-panel" component={MyPanel} />

        {/* ==== EVENTS ====  */}
        <Route path={`/events/:eventId(${idRegex})`} component={Event} />
        <Route path="/events/new" component={Event} />
        <Route path="/events" component={Events} />

        {/* ==== CONTACTS ====  */}
        <Route path={`/contacts/:contactId(${idRegex})`} component={Contact} />
        <Route path="/contacts/new" component={Contact} />
        <Route path="/contacts" component={Contacts} />

        {/* ==== PROFILE ====  */}
        <Route path="/profile" component={Profile} />
        <Redirect exact from="/" to="/my-panel" />
        <Redirect exact from="/sign-up" to="/my-panel" />
        <Route component={NotFound} />
      </Switch>
    </React.Suspense>
  );
};

export default Routes;
