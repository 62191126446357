import React, { useState } from 'react';
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Col,
  Card,
  Row,
  CardFooter,
  CardBody
} from 'reactstrap';
import gql from 'graphql-tag';
import QueryData from '../../components/QueryData';
import { NotifyContext } from '../../commen/NotifyContext';
import ProfileInfo from './ProfileInfo';
import ChangePassword from './ChangePassword';
import Payment from './Payment';
import MutationData from '../../components/MutationData';
import Sweetalert from '../../components/UI/Sweetalert';
// import { ReasonForCancel } from './ReasonForCancel';
import './Profile.css';

export const GET_USER_INFO = gql`
  query me {
    me {
      id
      email
      firstName
      lastName
      address
      zip
      city
      country
      phone
      companyName
    }
    getStripeUser {
      id
      sources {
        data {
          last4
          exp_month
          exp_year
        }
      }
      subscriptions {
        data {
          id
          current_period_end
          cancel_at_period_end
        }
      }
    }
    getPayments {
      data {
        amount
        description
        created
      }
    }
  }
`;

const CANCEL_SUP = gql`
  mutation CancelSubscription($supId: String!) {
    cancelSubscription(subscription: $supId)
  }
`;

function cancelSup(setAlert, components) {
  setAlert(components);
}

const Profile = () => {
  const [alert, setAlert] = useState(null);
  // const [reasons, setReasons] = useState({});
  // console.log('Profile reasons', reasons);

  return (
    <NotifyContext.Consumer>
      {({ showNotify }) => (
        <>
          {alert}
          <MutationData mutation={CANCEL_SUP}>
            {({ mutate }) => {
              return (
                <QueryData query={GET_USER_INFO}>
                  {({ me, getStripeUser, getPayments }) => {
                    const { data } = getStripeUser.subscriptions;
                    const canceled =
                      data.length <= 0 ||
                      (data[0] && data[0].cancel_at_period_end);
                    return (
                      <>
                        <Card
                          style={{ width: '80%', margin: '0 auto' }}
                          className="ProfileCard"
                        >
                          <CardBody>
                            <Row>
                              <Col lg={6}>
                                <Card>
                                  <ProfileInfo
                                    me={me}
                                    showNotify={showNotify}
                                  />
                                </Card>
                              </Col>
                              <Col lg={6}>
                                <Card>
                                  <ChangePassword
                                    me={me}
                                    showNotify={showNotify}
                                  />
                                </Card>
                              </Col>
                            </Row>
                            <Row>
                              <Card>
                                {/* {getStripeUser.sources.data.last4 ? ( */}
                                <Payment
                                  me={me}
                                  canceled={canceled}
                                  stripe={getStripeUser}
                                  payments={getPayments}
                                  showNotify={showNotify}
                                />
                                {/* ) : null} */}
                              </Card>
                            </Row>
                          </CardBody>
                          <CardFooter>
                            <Col md="12">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end'
                                }}
                              >
                                <UncontrolledDropdown direction="up">
                                  <DropdownToggle
                                    aria-expanded={false}
                                    aria-haspopup={true}
                                    caret
                                    className="btn-block"
                                    color="primary"
                                    data-toggle="dropdown"
                                    id="optionsMenuButton"
                                    type="button"
                                  >
                                    Administrér medlemsskab
                                  </DropdownToggle>
                                  <DropdownMenu aria-labelledby="dropdownMenuButton">
                                    {!canceled && (
                                      <DropdownItem
                                        onClick={() => {
                                          cancelSup(
                                            setAlert,
                                            <Sweetalert
                                              warning
                                              style={{ display: 'block' }}
                                              title="Du er ved at opsige dit medlemsskab hos ArtistButler"
                                              // text={
                                              //   <ReasonForCancel
                                              //     setValues={setReasons}
                                              //     values={reasons}
                                              //   />
                                              // }
                                              onConfirm={() => {
                                                // console.log(reasons);
                                                setAlert(null);
                                                mutate({
                                                  variables: {
                                                    supId:
                                                      getStripeUser
                                                        .subscriptions.data[0]
                                                        .id
                                                    // reasons
                                                  },
                                                  refetchQueries: [
                                                    {
                                                      query: GET_USER_INFO
                                                    }
                                                  ]
                                                }).then(({ data }) => {
                                                  if (data.cancelSubscription) {
                                                    showNotify({
                                                      message:
                                                        'Abonnement opsagt',
                                                      type: 'success',
                                                      icon:
                                                        'tim-icons fas fa-check'
                                                    });
                                                  }
                                                });
                                              }}
                                              onCancel={() => setAlert(null)}
                                              cancelBtnBsStyle="danger"
                                              confirmBtnText="Opsig medlemsskab"
                                              cancelBtnText="Annuller"
                                              showCancel
                                              hideAlert={() => setAlert(null)}
                                            />
                                          );
                                        }}
                                      >
                                        Opsig medlemsskab
                                      </DropdownItem>
                                    )}
                                    {/* <DropdownItem onClick={e => e.preventDefault()}>
                      Slet din bruger
                    </DropdownItem> */}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </Col>
                          </CardFooter>
                        </Card>
                      </>
                    );
                  }}
                </QueryData>
              );
            }}
          </MutationData>
        </>
      )}
    </NotifyContext.Consumer>
  );
};

export default Profile;
