import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect
} from 'react';
import NotificationAlert from 'react-notification-alert';

export const NotifyContext = createContext(null);

export const NotifyProvider = props => {
  const [options, setOptions] = useState({
    place: 'tc',
    autoDismiss: 4,
    closeButton: false
  });
  const [open, setOpen] = useState(false);

  const showNotify = notifyOptions => {
    setOptions({ ...options, ...notifyOptions });
    setOpen(true);
  };

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, [open]);

  return (
    <NotifyContext.Provider value={{ showNotify, options, open }}>
      {props.children}
    </NotifyContext.Provider>
  );
};

export const NotificationAlertRoot = () => {
  const { options, open } = useContext(NotifyContext);
  const ref = useRef(options);
  useEffect(() => {
    if (open) {
      ref.current.notificationAlert(options);
    }
  }, [open]);

  return <NotificationAlert ref={ref} />;
};
