import React from 'react';
import { Elements } from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import MutationData from '../../../components/MutationData';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../commen/AppContext';
import { NotifyContext } from '../../../commen/NotifyContext';

const REGISTER = gql`
  mutation Register($account: UserInput!, $source: String!) {
    register(account: $account, source: $source)
  }
`;
const UPDATE_PAYMENT = gql`
  mutation changePaymentMethod($source: String!) {
    changePaymentMethod(source: $source)
  }
`;

class Checkout extends React.Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ setUser }) => {
          return (
            <NotifyContext.Consumer>
              {({ showNotify }) => {
                return (
                  <MutationData
                    mutation={
                      this.props.updatePayment ? UPDATE_PAYMENT : REGISTER
                    }
                  >
                    {({ mutate, options }) => {
                      return (
                        <Elements>
                          <CheckoutForm
                            onSuccess={() =>
                              showNotify({
                                message:
                                  'Dine betalingsoplysninger er opdateret',
                                type: 'success',
                                icon: 'tim-icons fas fa-check'
                              })
                            }
                            options={options}
                            setUser={setUser}
                            mutate={mutate}
                            {...this.props}
                          />
                        </Elements>
                      );
                    }}
                  </MutationData>
                );
              }}
            </NotifyContext.Consumer>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default withRouter(Checkout);
