import * as yup from 'yup';
import { name, email /*phone*/ } from './types';

const onlyNumbers = /^\d+$/;
const phoneNumber = /^(?=.*\d)[\d ]+$/

export default yup.object().shape({
  phone: yup
    .string()
    .matches(phoneNumber, 'Telefonnumre må kun indeholde tal')
    .max(11, 'Telefonnumre skal være 8 cifre')
    .min(11, 'Telefonnumre skal være 8 cifre')
    .required('Kontakt skal have telefonnummer'),
  email,
  name,
  cvr: yup
    .string()
    .matches(onlyNumbers, 'CVR må kun indeholde tal')
    .max(8, 'CVR skal være på 8 cifre')
    .min(8, 'CVR skal være på 8 cifre'),
  zip: yup
    .string()
    .matches(onlyNumbers, 'Postnummer må kun indeholde tal')
    .max(4, 'Postnummer skal være på 4 cifre')
    .min(4, 'Postnummer skal være på 4 cifre')
});

// Disclaimer: Tried this mehtod, but it gives a weird error message if fx cvr was 9999999999 (or more)
// zip: yup
// .number()
// .positive()
// .integer()
// .min(1000, 'Postnummer skal være 4 cifre')
// .max(9999, 'Postnummer skal være 4 cifre')
// .typeError('Postnummer skal være tal'),
// cvr: yup
// .number()
// .positive()
// .integer()
// .min(10000000, 'CVR skal være 8 cifre')
// .max(99999999, 'CVR skal være 8 cifre')
// .typeError('CVR skal være tal'),
