import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import 'assets/scss/black-dashboard-pro-react.scss';
import { ApolloProvider, ApolloConsumer } from 'react-apollo';
import { client } from './apollo/client';
import { NotifyProvider } from './commen/NotifyContext';

document.body.classList.add('white-content');
const app = (
  <ApolloProvider client={client}>
    <NotifyProvider>
      <ApolloConsumer>
        {client => (
          <Router>
            <App client={client} />
          </Router>
        )}
      </ApolloConsumer>
    </NotifyProvider>
  </ApolloProvider>
);

render(app, document.getElementById('root'));
