import { API_HOST } from '../../../apollo/client';
import { getToken } from '../../../helper';

const url = API_HOST;

export const fetchData = async q => {
  const query = `
    query suggestContacts($query: String!) {
        suggestContacts(name: $query) {
          id
          company
          address
          zip
          city
          phone
          email
          name
        }
      }
    `;

  const variables = { query: q };
  const opts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify({ query, variables })
  };
  const res = await fetch(`${url}/graphql`, opts);
  const data = await res.json();
  return data;
};
