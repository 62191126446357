import React, { Component } from 'react';
import { Link, Prompt } from 'react-router-dom';
import SortingTable from '../../components/SortingTable/SortingTable';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  ModalBody,
  Modal,
  CardHeader,
  CardTitle
} from 'reactstrap';
import gql from 'graphql-tag';
import QueryData from '../../components/QueryData';
import classes from './styles.module.scss';
import styles from './styles.module.scss';
import './ContactsTableOverride.css';
import './modalPopUp.css';
import { NotifyContext } from '../../commen/NotifyContext';
import MutationData from '../../components/MutationData';
import { Formik, Form, Field } from 'formik';
import contactSchema from '../../validationSchema/contactSchema';
import { INIT_VALUES } from './Contact/initValue';
import Input from '../../components/FormFields/Input';
import { formatPhone } from '../../utils/format';
import PhoneNumberInput from '../../components/FormFields/PhoneNumberInput';


const GET_CONTACT = gql`
  query GetContact($id: ID!) {
    getContact(id: $id) {
      name
      cvr
      company
      address
      zip
      city
      phone
      email
      role
    }
  }
`;
export const CREATE_CONTACT = gql`
  mutation CreateContact($ContactInput: ContactInput!) {
    createContact(contact: $ContactInput) {
      id
    }
  }
`;

const UPDATE_CONTACT = gql`
  mutation UpdateContact($id: ID!, $ContactInput: ContactInput!) {
    updateContact(id: $id, contact: $ContactInput) {
      id
    }
  }
`;

// export to: Contact.js
export const GET_CONTACTS = gql`
  query GetContacts {
    getContacts {
      id
      name
      email
      phone
      company
    }
  }
`;

const columns = [
  {
    Header: 'Navn',
    accessor: 'name',
    headerStyle: { textAlign: 'left' },
    Filter: ({ filter, onChange }) => (
      <input
        type="text"
        placeholder="Søg i navne"
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
      />
    ),
    filterMethod: (filter, row) =>
      row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
    Cell: props => {
      return (
        <Link
          to={`/contacts/${props.original.id}`}
          style={{ color: '#11130', fontWeight: 'bold' }}
        >
          {props.value}
        </Link>
      );
    }
  },
  {
    Header: 'Virksomhed',
    accessor: 'company',
    headerStyle: { textAlign: 'left' },
    Filter: ({ filter, onChange }) => (
      <input
        type="text"
        placeholder="Søg i virksomheder"
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
      />
    ),
    filterMethod: (filter, row) =>
      row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
  },
  {
    Header: 'Telefonnummer',
    accessor: 'phone',
    headerStyle: { textAlign: 'left' },
    Filter: ({ filter, onChange }) => (
      <input
        type="text"
        placeholder="Søg i telefonnumre"
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
      />
    ),
    filterMethod: (filter, row) =>
      row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    headerStyle: { textAlign: 'left' },
    Filter: ({ filter, onChange }) => (
      <input
        type="text"
        placeholder="Søg i e-mails"
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
      />
    ),
    filterMethod: (filter, row) =>
      row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
  }
];

function serializer(values) {
  return values.map(({ phone, ...rest }) => ({
    ...rest,
    phone: formatPhone(phone)
  }));
}

function deserializer(values) {
  const {phone, ...rest} = values
  return {
    phone: phone.replace(/\W/gi, '').replace(/(.{2})/g, '$1').trim(),
    ...rest
  }
}

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpened:
        document.documentElement.className.indexOf('nav-open') !== -1,
      modalDemo: false
    };
    this.toggleModalDemo = this.toggleModalDemo.bind(this);
  }

  toggleModalDemo() {
    this.setState({
      modalDemo: !this.state.modalDemo
    });
  }
  setDirty = dirty => {
    this.setState({ dirty });
  };

  render() {
    const id = this.props.match.params.contactId;
    return (
      <QueryData query={GET_CONTACTS}>
        {({ getContacts }) => {
          return (
            <Row>
              <Col className="mb-5" md="12">
                <p className={styles.CardHeader}>Kontakter</p>
                <Card>
                  <CardBody>
                    <CardHeader>
                      <div className="contactButtonDiv">
                        <Button
                          color="primary"
                          onClick={this.toggleModalDemo}
                          className="CreateANewContactBtn"
                        >
                          <i className="tim-icons fas fa-plus" /> Tilføj kontakt
                        </Button>
                      </div>
                    </CardHeader>
                    {/* ========== CreateContactModal ========== */}
                    <div>
                      <Modal
                        isOpen={this.state.modalDemo}
                        toggle={this.toggleModalDemo}
                      >
                        <ModalBody>
                          <NotifyContext.Consumer>
                            {({ showNotify }) => (
                              <QueryData
                                query={GET_CONTACT}
                                variables={{ id: id ? id : '' }}
                              >
                                {({ getContact, options }) => {
                                  return (
                                    <MutationData
                                      mutation={
                                        id ? UPDATE_CONTACT : CREATE_CONTACT
                                      }
                                    >
                                      {({ mutate }) => {
                                        return (
                                          <Formik
                                            validationSchema={contactSchema}
                                            initialValues={
                                              id ? getContact : INIT_VALUES
                                            }
                                            onSubmit={(values, actions) => {
                                              mutate({
                                                variables: {
                                                  id: id ? id : null,
                                                  ContactInput: deserializer(values)
                                                },
                                                refetchQueries: [
                                                  { query: GET_CONTACTS }
                                                ]
                                              }).then(res => {
                                                actions.setSubmitting(false);
                                                this.setDirty(false);
                                                this.toggleModalDemo(false);
                                                if (res) {
                                                  if (id) {
                                                    options.refetch();
                                                  }
                                                  showNotify({
                                                    message: `Din Kontakt er ${
                                                      !id
                                                        ? 'oprettet'
                                                        : 'opdateret'
                                                    }`,
                                                    type: 'success',
                                                    icon:
                                                      'tim-icons fas fa-check'
                                                  });
                                                  return;
                                                }
                                              });
                                            }}
                                          >
                                            {({
                                              values,
                                              isSubmitting,
                                              dirty
                                            }) => {
                                              return (
                                                <Form>
                                                  {this.state.alert}
                                                  {id && (
                                                    <Prompt
                                                      when={
                                                        this.state.dirty !==
                                                        null
                                                          ? this.state.dirty
                                                          : dirty
                                                      }
                                                      message={() => {
                                                        return `Du har ændringer som ikke er gemt. \rTryk "Anuller" og derefter "Gem" for at gemme`;
                                                      }}
                                                    />
                                                  )}
                                                  <Card>
                                                    <CardBody>
                                                      <CardHeader>
                                                        <CardTitle
                                                          tag="h2"
                                                          className="text-center"
                                                        >
                                                          {id
                                                            ? values.name || (
                                                                <span>
                                                                  &nbsp;
                                                                </span>
                                                              )
                                                            : 'Opret Ny Kontakt'}
                                                        </CardTitle>
                                                        <CardTitle
                                                          tag="h5"
                                                          className="text-center"
                                                        >
                                                          <Button
                                                            color="link"
                                                            target="_black"
                                                            href="https://www.artistbutler.dk/starthjaelp"
                                                          >
                                                            <i class="fas fa-film fa-lg"></i>{' '}
                                                            Sådan Gør Du
                                                          </Button>
                                                        </CardTitle>
                                                      </CardHeader>
                                                      <b
                                                        className="LabelReplacerLabel"
                                                        style={{
                                                          fontWeight: 'normal'
                                                        }}
                                                      >
                                                        *Navn
                                                      </b>
                                                      <Field
                                                        label="Navn (skal udfyldes)"
                                                        name="name"
                                                        type="text"
                                                        component={Input}
                                                        placeholder="Navn"
                                                      />
                                                      <Row>
                                                        <Col lg={6}>
                                                          <b
                                                            className="LabelReplacerLabel"
                                                            style={{
                                                              fontWeight:
                                                                'normal'
                                                            }}
                                                          >
                                                            *Telefonnummer
                                                          </b>
                                                          <Field
                                                            label="Telefonnummer (skal udfyldes)"
                                                            name="phone"
                                                            component={PhoneNumberInput}
                                                            placeholder="Telefonnummer"
                                                          />
                                                        </Col>
                                                        <Col lg={6}>
                                                          <b
                                                            className="LabelReplacerLabel"
                                                            style={{
                                                              fontWeight:
                                                                'normal'
                                                            }}
                                                          >
                                                            *E-mail
                                                          </b>
                                                          <Field
                                                            label="E-mail (skal udfyldes)"
                                                            name="email"
                                                            component={Input}
                                                            placeholder="E-mail"
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <b className="LabelReplacerLabel">
                                                        Adresse
                                                      </b>
                                                      <Field
                                                        label="Adresse"
                                                        name="address"
                                                        type="text"
                                                        component={Input}
                                                        placeholder="Adresse"
                                                      />
                                                      <Row>
                                                        <Col lg={6}>
                                                          <b className="LabelReplacerLabel">
                                                            Postnr.
                                                          </b>
                                                          <Field
                                                            label="Postnr."
                                                            name="zip"
                                                            component={Input}
                                                            placeholder="Postnr."
                                                          />
                                                        </Col>
                                                        <Col lg={6}>
                                                          <b className="LabelReplacerLabel">
                                                            By
                                                          </b>
                                                          <Field
                                                            label="By"
                                                            name="city"
                                                            component={Input}
                                                            placeholder="By"
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <Row>
                                                        <Col lg={6}>
                                                          <b className="LabelReplacerLabel">
                                                            Evt. virksomhed
                                                          </b>
                                                          <Field
                                                            label="Evt. Virksomhed"
                                                            name="company"
                                                            component={Input}
                                                            placeholder="Virksomhedsnavn"
                                                          />
                                                        </Col>
                                                        <Col lg={6}>
                                                          <b className="LabelReplacerLabel">
                                                            Evt. CVR
                                                          </b>
                                                          <Field
                                                            label="Evt. CVR"
                                                            name="cvr"
                                                            component={Input}
                                                            placeholder="CVR"
                                                          />
                                                        </Col>
                                                      </Row>
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          justifyContent:
                                                            'space-between'
                                                        }}
                                                      >
                                                        <Link
                                                          to="/contacts"
                                                          className="link footer-link"
                                                        >
                                                          <Button
                                                            className={
                                                              'btn-primary'
                                                            }
                                                            style={{
                                                              paddingRight:
                                                                '20px',
                                                              paddingLeft:
                                                                '20px'
                                                            }}
                                                            onClick={
                                                              this
                                                                .toggleModalDemo
                                                            }
                                                          >
                                                            Tilbage
                                                          </Button>
                                                        </Link>

                                                        <Button
                                                          color="primary"
                                                          type="submit"
                                                        >
                                                          {isSubmitting ? (
                                                            <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
                                                          ) : id ? (
                                                            'Gem'
                                                          ) : (
                                                            'Opret kontakt'
                                                          )}
                                                        </Button>
                                                      </div>
                                                    </CardBody>
                                                  </Card>
                                                </Form>
                                              );
                                            }}
                                          </Formik>
                                        );
                                      }}
                                    </MutationData>
                                  );
                                }}
                              </QueryData>
                            )}
                          </NotifyContext.Consumer>
                        </ModalBody>
                      </Modal>
                    </div>
                    {/* ========== CreateContactModal ========== */}

                    <SortingTable
                      className="contactsTable"
                      getTrProps={(_, rowInfo) => {
                        return {
                          onClick: () => {
                            this.props.history.push(
                              `/contacts/${rowInfo.original.id}`
                            );
                          },
                          className: rowInfo && classes.hover
                        };
                      }}
                      noDataText={'Ingen kontakter endnu'}
                      data={serializer(getContacts)}
                      columns={columns}
                      filterable
                      defaultSorted={[
                        {
                          id: 'name',
                          desc: false
                        }
                      ]}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          );
        }}
      </QueryData>
    );
  }
}

export default Contacts;
