import React from 'react';
import { Mutation } from 'react-apollo';
import { NotifyContext } from '../commen/NotifyContext';

const MutationData = ({ mutation, children }) => {
  return (
    <NotifyContext.Consumer>
      {({ showNotify }) => (
        <Mutation mutation={mutation}>
          {(mutationQuery, options) => {
            const mutate = async ({ variables, refetchQueries = [] }) => {
              try {
                const res = await mutationQuery({ variables, refetchQueries });
                return res;
              } catch (err) {
                let errMsg = 'Der skete en fejl';
                if (err.message) {
                  errMsg = err.message.split(': ')[1];
                }

                showNotify({
                  message: errMsg,
                  type: 'danger',
                  autoDismiss: 8,
                  icon: 'tim-icons fas fa-times'
                });
                return null;
              }
            };
            return children({ mutate, options });
          }}
        </Mutation>
      )}
    </NotifyContext.Consumer>
  );
};

export default MutationData;
