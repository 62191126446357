import React, { Component } from 'react';
import QueryData from '../../../components/QueryData';
import MutationData from '../../../components/MutationData';
import gql from 'graphql-tag';
import { Formik, Form, Field } from 'formik';
import Input from '../../../components/FormFields/Input';
import PhoneNumberInput from '../../../components/FormFields/PhoneNumberInput';
import { INIT_VALUES } from './initValue';
import { Prompt, Link } from 'react-router-dom';
import {
  Button,
  Card,
  Row,
  CardHeader,
  CardBody,
  CardTitle,
  Col,
  CardFooter
} from 'reactstrap';
import { GET_CONTACTS } from '..';
import { NotifyContext } from '../../../commen/NotifyContext';
import contactSchema from '../../../validationSchema/contactSchema';
import Sweetalert from '../../../components/UI/Sweetalert';
import '../../../CreatingCards.css';
import './Contact.css';
const GET_CONTACT = gql`
  query GetContact($id: ID!) {
    getContact(id: $id) {
      name
      cvr
      company
      address
      zip
      city
      phone
      email
      role
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation CreateContact($ContactInput: ContactInput!) {
    createContact(contact: $ContactInput) {
      id
    }
  }
`;

const UPDATE_CONTACT = gql`
  mutation UpdateContact($id: ID!, $ContactInput: ContactInput!) {
    updateContact(id: $id, contact: $ContactInput) {
      id
    }
  }
`;

const DELETE_CONTACT = gql`
  mutation DeleteContact($id: ID!) {
    deleteContact(id: $id)
  }
`;

function serializer(values) {
  const { phone, ...rest } = values;
  return {
    phone: (phone || '')
      .replace(/\W/gi, '')
      .replace(/(.{2})/g, '$1 ')
      .trim(),
    ...rest
  };
}

function deserializer(values) {
  const { phone, ...rest } = values;
  return {
    phone: (phone || '')
      .replace(/\W/gi, '')
      .replace(/(.{2})/g, '$1')
      .trim(),
    ...rest
  };
}

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      dirty: null
    };
  }

  hideAlert = () => {
    this.setState({ alert: null });
  };

  showModal = onConfirm => {
    this.setState({
      alert: (
        <Sweetalert
          title={'Vil du slette kontakten?'}
          onConfirm={() => this.onConfirm()}
          hideAlert={() => this.hideAlert()}
        />
      )
    });
  };

  setDirty = dirty => {
    this.setState({ dirty });
  };

  render() {
    const id = this.props.match.params.contactId;
    return (
      <NotifyContext.Consumer>
        {({ showNotify }) => (
          <QueryData query={GET_CONTACT} variables={{ id: id ? id : '' }}>
            {({ getContact, options }) => {
              return (
                <MutationData mutation={id ? UPDATE_CONTACT : CREATE_CONTACT}>
                  {({ mutate }) => {
                    return (
                      <Formik
                        validationSchema={contactSchema}
                        initialValues={
                          id ? serializer(getContact) : INIT_VALUES
                        }
                        onSubmit={(values, actions) => {
                          mutate({
                            variables: {
                              id: id ? id : null,
                              ContactInput: deserializer(values)
                            },
                            refetchQueries: [{ query: GET_CONTACTS }]
                          }).then(res => {
                            actions.setSubmitting(false);
                            this.setDirty(false);
                            if (res) {
                              if (!id) {
                                this.props.history.push(
                                  `/contacts/${res.data.createContact.id}`
                                );
                              }
                              if (id) {
                                options.refetch();
                              }
                              showNotify({
                                message: `Din Kontakt er ${
                                  !id ? 'oprettet' : 'opdateret'
                                }`,
                                type: 'success',
                                icon: 'tim-icons fas fa-check'
                              });
                              return;
                            }
                          });
                        }}
                      >
                        {({ values, isSubmitting, dirty }) => {
                          return (
                            <Form>
                              {this.state.alert}
                              {id && (
                                <Prompt
                                  when={
                                    this.state.dirty !== null
                                      ? this.state.dirty
                                      : dirty
                                  }
                                  message={() => {
                                    return `Du har ændringer som ikke er gemt. \rTryk "Anuller" og derefter "Gem" for at gemme`;
                                  }}
                                />
                              )}
                              <Card className="ContactCard">
                                <Row>
                                  <Col lg={6}>
                                    <CardHeader className="ContactCardHeader">
                                      <CardTitle
                                        tag="h2"
                                        className="text-center"
                                      />
                                      <CardTitle tag="h3">
                                        <i className="fas fa-user-circle fa-lg" />{' '}
                                        <strong>
                                          {id
                                            ? values.company ||
                                              values.name || <span>&nbsp;</span>
                                            : 'Opret ny kontakt'}
                                        </strong>
                                        <br />
                                        <Button
                                          color="link"
                                          target="_black"
                                          href="https://www.artistbutler.dk/starthjaelp"
                                        >
                                          <i class="fas fa-film fa-lg" /> Sådan
                                          Gør Du
                                        </Button>
                                      </CardTitle>
                                    </CardHeader>
                                    <CardBody className="ContactCardBody">
                                      <Row>
                                        <Col
                                          lg={4}
                                          className="LabelReplacerCol"
                                        >
                                          <b
                                            className="LabelReplacerLabel"
                                            style={{ fontWeight: 'normal' }}
                                          >
                                            *Navn
                                          </b>
                                        </Col>
                                        <Col>
                                          <Field
                                            label="Navn (skal udfyldes)"
                                            name="name"
                                            type="text"
                                            component={Input}
                                            placeholder="Navn"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col
                                          lg={4}
                                          className="LabelReplacerCol"
                                        >
                                          <b className="LabelReplacerLabel">
                                            Adresse
                                          </b>
                                        </Col>
                                        <Col>
                                          <Field
                                            label="Adresse"
                                            name="address"
                                            type="text"
                                            component={Input}
                                            placeholder="Adresse"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={4} />
                                        <Col lg={3}>
                                          <Field
                                            label="Postnr."
                                            name="zip"
                                            component={Input}
                                            placeholder="Postnr."
                                          />
                                        </Col>
                                        <Col lg={5}>
                                          <Field
                                            label="By"
                                            name="city"
                                            component={Input}
                                            placeholder="By"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col
                                          lg={4}
                                          className="LabelReplacerCol"
                                        >
                                          <b
                                            className="LabelReplacerLabel"
                                            style={{ fontWeight: 'normal' }}
                                          >
                                            *Telefonnummer
                                          </b>
                                        </Col>
                                        <Col>
                                          <Field
                                            label="Telefonnummer (skal udfyldes)"
                                            name="phone"
                                            component={PhoneNumberInput}
                                            placeholder="Telefonnummer"
                                            type="text"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col
                                          lg={4}
                                          className="LabelReplacerCol"
                                        >
                                          <b
                                            className="LabelReplacerLabel"
                                            style={{ fontWeight: 'normal' }}
                                          >
                                            *E-mail
                                          </b>
                                        </Col>
                                        <Col>
                                          <Field
                                            label="E-mail (skal udfyldes)"
                                            name="email"
                                            component={Input}
                                            placeholder="E-mail"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col
                                          lg={4}
                                          className="LabelReplacerCol"
                                        >
                                          <b className="LabelReplacerLabel">
                                            Virksomhedsnavn
                                          </b>
                                        </Col>
                                        <Col>
                                          <Field
                                            label="Evt. Virksomhed"
                                            name="company"
                                            component={Input}
                                            placeholder="Virksomhedsnavn"
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col
                                          lg={4}
                                          className="LabelReplacerCol"
                                        >
                                          <b className="LabelReplacerLabel">
                                            Evt. CVR
                                          </b>
                                        </Col>
                                        <Col>
                                          <Field
                                            label="Evt. CVR"
                                            name="cvr"
                                            component={Input}
                                            placeholder="CVR"
                                          />
                                        </Col>
                                      </Row>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between'
                                        }}
                                      >
                                        <Link
                                          to="/contacts"
                                          className="link footer-link"
                                        >
                                          <Button
                                            className={'btn-primary'}
                                            style={{
                                              paddingRight: '20px',
                                              paddingLeft: '20px'
                                            }}
                                            type="button"
                                          >
                                            Tilbage
                                          </Button>
                                        </Link>
                                        <div>
                                          <MutationData
                                            mutation={DELETE_CONTACT}
                                          >
                                            {({ mutate }) => {
                                              return (
                                                <Button
                                                  onClick={() => {
                                                    this.setState({
                                                      alert: (
                                                        <Sweetalert
                                                          showCancel
                                                          confirmBtnBsStyle="danger"
                                                          cancelBtnBsStyle="primary"
                                                          confirmBtnText="Slet"
                                                          cancelBtnText="Annuller"
                                                          title={
                                                            'Vil du slette denne kontakt?'
                                                          }
                                                          onConfirm={() =>
                                                            mutate({
                                                              variables: {
                                                                id
                                                              },
                                                              refetchQueries: [
                                                                {
                                                                  query: GET_CONTACTS
                                                                }
                                                              ]
                                                            }).then(() => {
                                                              this.props.history.push(
                                                                `/contacts`
                                                              );
                                                              showNotify({
                                                                message:
                                                                  'Kontakten er slettet',
                                                                type: 'success',
                                                                icon:
                                                                  'tim-icons fas fa-check'
                                                              });
                                                            })
                                                          }
                                                          hideAlert={() =>
                                                            this.hideAlert()
                                                          }
                                                        />
                                                      )
                                                    });
                                                  }}
                                                  color="link"
                                                  type="button"
                                                  style={{ color: 'red' }}
                                                >
                                                  Slet
                                                </Button>
                                              );
                                            }}
                                          </MutationData>
                                          <Button color="primary" type="submit">
                                            {isSubmitting ? (
                                              <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
                                            ) : id ? (
                                              'Gem'
                                            ) : (
                                              'Opret kontakt'
                                            )}
                                          </Button>
                                        </div>
                                      </div>
                                    </CardBody>
                                    <CardFooter
                                      style={{
                                        paddingRight: '45px',
                                        paddingLeft: '45px'
                                      }}
                                    />
                                  </Col>
                                  <Col lg={6} />
                                </Row>
                              </Card>
                            </Form>
                          );
                        }}
                      </Formik>
                    );
                  }}
                </MutationData>
              );
            }}
          </QueryData>
        )}
      </NotifyContext.Consumer>
    );
  }
}

export default Contact;
