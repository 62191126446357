var routes = [
  {
    path: '/my-panel',
    name: 'Mit Panel',
    icon: 'tim-icons fas fa-columns'
  },
  {
    path: '/events',
    name: 'Events',
    icon: 'tim-icons fas fa-star'
  },
  {
    path: '/contacts',
    name: 'Kontakter',
    icon: 'tim-icons fas fa-users'
  },
  // {
  //   path: '/firm',
  //   name: 'Virksomhed',
  //   icon: 'tim-icons fas fa-city'
  // },
  {
    path: '/profile',
    name: 'Min profil',
    icon: 'tim-icons fas fa-user'
  }
];
export default routes;
