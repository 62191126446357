import React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import Checkout from './Checkout';

class Payment extends React.Component {
  render() {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_PAY_KEY}>
        <Checkout {...this.props} />
      </StripeProvider>
    );
  }
}

export default Payment;
