import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import Card from './Card';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
  // FormGroup, Label, Input as Checkbox
} from 'reactstrap';
import styles from './styles.module.scss';
import { setToken } from '../../../helper';
import { client } from '../../../apollo/client';
import { ME } from '../../../App';
import { GET_USER_INFO } from '../../Profile';
import TaCText from './TaC';

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptTermsAndConditions: this.props.updatePayment ? true : false,
      acceptTermsAndConditionsError: false,
      showTermsAndConditions: false
    };
  }

  onChange = ev => {
    const { checked } = ev.target;

    this.setState({
      acceptTermsAndConditions: checked,
      acceptTermsAndConditionsError: false
    });
  };

  // showTaC = () => {
  //   this.setState(state => ({
  //     showTermsAndConditions: !state.showTermsAndConditions
  //   }));
  // };

  handleSubmit = async ev => {
    ev.preventDefault();
    if (this.state.acceptTermsAndConditions === false) {
      this.setState({ acceptTermsAndConditionsError: true });
      return;
    }
    const { token } = await this.props.stripe.createToken();
    if (!this.props.updatePayment) {
      const variables = {
        source: token.id,
        account: { ...this.props.user }
      };
      this.props
        .mutate({ variables })
        .then(res => {
          if (res && res.data && res.data.register) {
            setToken(res.data.register);
            client
              .query({ query: ME, errorPolicy: 'all' })
              .then(res => {
                if (res && res.data) {
                  this.props.setUser(res.data);
                }
              })
              .catch(err => {});
          }
        })
        .catch(err => {});
    } else {
      const variables = {
        source: token.id
      };
      const refetchQueries = [{ query: GET_USER_INFO }];
      this.props.mutate({ variables, refetchQueries }).then(() => {
        this.props.setUpdatePayment(false);
        this.props.onSuccess();
      });
    }
  };

  render() {
    return (
      <>
        <Modal
          scrollable={true}
          size={'lg'}
          backdrop={this.state.showTermsAndConditions}
          isOpen={this.state.showTermsAndConditions}
          toggle={() => this.showTaC()}
        >
          <ModalHeader toggle={() => this.showTaC()}>
            Vilkår og betingelser
          </ModalHeader>
          <ModalBody>{TaCText}</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={() => this.showTaC()}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
        <form onSubmit={this.handleSubmit}>
          {!this.props.updatePayment && (
            <p className={styles.infoText}>
              Din prøveperiode er gældende i 30 dage, dernæst kr. 149,- pr.
              måned. <br></br>
              Vi trækker intet beløb før din prøveperiode udløber.
            </p>
          )}
          <Card />

          {/* <FormGroup check>
          <Label check>
            <Checkbox
              id="acceptTermsAndConditions"
              type="checkbox"
              name="acceptTermsAndConditions"
              checked={this.state.acceptTermsAndConditions}
              onChange={this.onChange}
            />{' '}
            Jeg accepterer{' '}
            <a
              style={{ color: '#0000EE', textDecoration: 'underline' }}
              onClick={() => console.log('open modal')}
            >
              {' '}
              vilkår og betingelser
            </a>
            <span className="form-check-sign">
              <span className="check" />
            </span>
          </Label>
        </FormGroup> */}

          {!this.props.updatePayment && (
            <label
              style={{
                fontSize: '1em',
                color: this.state.acceptTermsAndConditionsError ? 'red' : ''
              }}
              htmlFor={'acceptTermsAndConditions'}
            >
              <input
                style={{ marginRight: '0.5em' }}
                id='acceptTermsAndConditions'
                name='acceptTermsAndConditions'
                type='checkbox'
                onChange={this.onChange}
                checked={this.state.acceptTermsAndConditions}
              />
              Jeg accepterer{' '}
              <a
                style={{ color: '#0000EE', textDecoration: 'underline' }}
                // onClick={() => this.showTaC()}
                target='_black'
                href='https://www.artistbutler.dk/generelle-betingelser'
              >
                {' '}
                vilkår og betingelser
              </a>
            </label>
          )}
          {this.state.acceptTermsAndConditionsError && (
            <p style={{ color: 'red' }}>Påkrævet</p>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1em'
            }}
          >
            <Button className={'btn-primary'} type='submit'>
              {this.props.options.loading ? (
                <i className='tim-icon fas fa-spinner fa-spin fa-pulse' />
              ) : (
                this.props.buttonText || 'Start prøveperiode!'
              )}
            </Button>
          </div>
        </form>
      </>
    );
  }
}

export default injectStripe(CheckoutForm);
