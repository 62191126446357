export function setSuggestContact(
  selected,
  section,
  setValues,
  values,
  idx,
  replace
) {
  const hasCompany = selected.company !== '';
  if (section === 'venue') {
    setValues({
      ...values,
      venuePlaceName: hasCompany ? selected.company : selected.name,
      venueAddress: selected.address,
      venueZipCode: selected.zip,
      venueCity: selected.city,
      venueRegisterNumber: selected.cvr,
      venuePhone: selected.phone,
      venueEmail: selected.email,
      venueContactPerson: hasCompany ? selected.name : ''
    });
  }
  if (section === 'organizer') {
    setValues({
      ...values,
      organizerName: hasCompany ? selected.company : selected.name,
      organizerAddress: selected.address,
      organizerZipCode: selected.zip,
      organizerCity: selected.city,
      organizerRegisterNumber: selected.cvr,
      organizerPhone: selected.phone,
      organizerEmail: selected.email,
      organizerContactPerson: hasCompany ? selected.name : ''
    });
  }
  if (section === 'contactPersons') {
    replace(idx, {
      ...values.contactPersons[idx],
      name: selected.name,
      phone: selected.phone,
      email: selected.email,
      roll: ''
    });
  }
}
