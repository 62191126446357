import React from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  Col,
  CardBody,
  Row,
  Button,
  CardFooter
} from 'reactstrap';
import { Prompt } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import gql from 'graphql-tag';
import MutationData from 'components/MutationData';
import resetPasswordSchema from '../../validationSchema/resetPasswordSchema';
import Input from 'components/FormFields/Input';
import './Profile.css';
const CHANGE_PASSWORD = gql`
  mutation($id: ID!, $email: String, $password: String!) {
    changePassword(id: $id, email: $email, password: $password)
  }
`;

const ChangePassword = props => {
  return (
    <MutationData mutation={CHANGE_PASSWORD}>
      {({ mutate }) => {
        return (
          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={resetPasswordSchema}
            onSubmit={(values, actions) => {
              mutate({
                variables: {
                  id: props.me.id,
                  email: props.me.email,
                  password: values.password
                }
              }).then(res => {
                actions.setSubmitting(false);
                if (res) {
                  props.showNotify({
                    message: 'Dit password er opdateret',
                    type: 'success',
                    icon: 'tim-icons fas fa-check'
                  });
                  actions.resetForm();
                }
              });
            }}
          >
            {({ isSubmitting, dirty }) => {
              return (
                <Form>
                  <Prompt
                    when={dirty}
                    message={() => {
                      return `Du har ændringer som ikke er gemt. \rTryk "Cancel" og derefter "Gem" for at gemme`;
                    }}
                  />
                  <Col md="12" className="ChangePasswordMainCol">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h3">
                          <i className="fas fa-lock fa-lg"></i>{' '}
                          <strong>Skift password</strong>
                        </CardTitle>
                      </CardHeader>
                      <CardBody className="ChangePasswordCardBody">
                        <Row>
                          <Col lg={4}>
                            <b className="LabelReplacerLabel">Nyt password</b>
                          </Col>
                          <Col>
                            <Field
                              label="Nyt Password"
                              name="password"
                              component={Input}
                              placeholder="Nyt password"
                              type="password"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4}>
                            <b className="LabelReplacerLabel">
                              Gentag password
                            </b>
                          </Col>
                          <Col>
                            <Field
                              label="Gentag password"
                              name="confirmPassword"
                              type="password"
                              component={Input}
                              placeholder="Gentag password"
                            />
                          </Col>
                        </Row>
                        <CardFooter>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <span style={{ marginLeft: '1em' }}>
                              <Button
                                disabled={isSubmitting}
                                color="primary"
                                type="submit"
                              >
                                {isSubmitting ? (
                                  <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
                                ) : (
                                  'Opdater'
                                )}
                              </Button>
                            </span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </MutationData>
  );
};

export default ChangePassword;
