import React, { Component } from 'react';
import MutationData from '../../components/MutationData';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Col
} from 'reactstrap';
import gql from 'graphql-tag';
import { Form, Field, Formik } from 'formik';
import Input from '../../components/FormFields/Input';
import { NotifyContext } from '../../commen/NotifyContext';
import jwtDecode from 'jwt-decode';
import resetPasswordSchema from '../../validationSchema/resetPasswordSchema';
import { Link } from 'react-router-dom';
import loginBG from 'assets/img/loginBG.jpg';

const REQ_RESET_PASSWORD = gql`
  mutation($email: String!) {
    resetPassword(email: $email)
  }
`;
const CHANGE_PASSWORD = gql`
  mutation($id: ID!, $email: String, $password: String!) {
    changePassword(id: $id, email: $email, password: $password)
  }
`;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }
  componentDidMount() {
    document.body.classList.toggle('login-page');
  }
  componentWillUnmount() {
    document.body.classList.toggle('login-page');
  }

  decodeToken = token => {
    return jwtDecode(token);
  };

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token && Date.now() >= this.decodeToken(token).exp * 1000) {
      return (
        <p style={{ paddingLeft: '10px' }}>
          Linket er desværre udløbet, gå til{' '}
          <Link style={{ color: 'blue' }} to="/resetPassword">
            Glemt kode
          </Link>{' '}
          og få et nyt link
        </p>
      );
    }

    return (
      <NotifyContext.Consumer>
        {({ showNotify }) => {
          return (
            <MutationData
              mutation={!token ? REQ_RESET_PASSWORD : CHANGE_PASSWORD}
            >
              {({ mutate }) => {
                return (
                  <>
                    <div
                      style={{
                        backgroundImage: `url(${loginBG})`,
                        width: '100vw',
                        height: '100vh',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover'
                      }}
                    ></div>
                    <Container style={{ paddingTop: '7%' }}>
                      <Col className="ml-auto mr-auto" lg="5">
                        <Card className="card-login card-white">
                          <CardHeader className="text-center">
                            <img
                              alt="..."
                              src={require('assets/img/AB_logo.png')}
                              style={{
                                width: '150px',
                                marginTop: '15px',
                                position: 'relative'
                              }}
                            />
                          </CardHeader>
                          <CardBody>
                          <CardTitle
                                tag="h2"
                                className="text-center"
                                style={{
                                  fontWeight: 'Lighter',
                                  fontFamily: 'Roboto',
                                  marginTop: '30px'
                                }}
                              >
                              {!token ? 'Nulstil dit password' : 'Ny Kode'}
                            </CardTitle>
                            {!token ? (
                              <Formik
                                initialValues={{ email: '' }}
                                // validationSchema={loginSchema}
                                onSubmit={(values, actions) => {
                                  const variables = {
                                    email: values.email
                                  };
                                  mutate({
                                    variables
                                  }).then(res => {
                                    showNotify({
                                      message: res.data.resetPassword,
                                      type: 'info',
                                      autoDismiss: 10
                                      // icon: 'tim-icons fas info'
                                    });
                                    actions.setSubmitting(false);
                                  });
                                }}
                                validateOnChange={false}
                                render={({ isSubmitting }) => {
                                  return (
                                    <Form>
                                      <Field
                                        style={{ fontSize: '16px' }}
                                        icon={'tim-icons fas fa-envelope'}
                                        name="email"
                                        type="email"
                                        component={Input}
                                        placeholder="Email"
                                      />

                                      <Button
                                        type="submit"
                                        block
                                        className="mb-3"
                                        color="primary"
                                        size="lg"
                                      >
                                        {isSubmitting ? (
                                          <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
                                        ) : (
                                          <i className="tim-icons fas fa-paper-plane" />
                                        )}{' '}
                                        Send hjælp
                                      </Button>
                                    </Form>
                                  );
                                }}
                              />
                            ) : (
                              <Formik
                                initialValues={{
                                  password: '',
                                  confirmPassword: ''
                                }}
                                validationSchema={resetPasswordSchema}
                                onSubmit={(values, actions) => {
                                  const variables = {
                                    id: this.decodeToken(token).userId,
                                    email: this.decodeToken(token).userMail,
                                    password: values.password
                                  };
                                  mutate({
                                    variables
                                  }).then(res => {
                                    showNotify({
                                      message: res.data.changePassword,
                                      type: 'success',
                                      icon: 'tim-icons fas fa-check'
                                    });
                                    actions.setSubmitting(false);
                                    this.props.history.push('/');
                                  });
                                }}
                                validateOnChange={false}
                                render={({ isSubmitting }) => {
                                  return (
                                    <Form>
                                      <Field
                                        icon={'tim-icons fas fa-lock'}
                                        name="password"
                                        type="password"
                                        component={Input}
                                        placeholder="Nyt password"
                                      />

                                      <Field
                                        icon={'tim-icons fas fa-lock'}
                                        name="confirmPassword"
                                        type="password"
                                        component={Input}
                                        placeholder="Gentag Nyt password"
                                      />

                                      <Button
                                        type="submit"
                                        block
                                        className="mb-3"
                                        color="primary"
                                        size="lg"
                                      >
                                        {isSubmitting ? (
                                          <i className="tim-icon fas fa-spinner fa-spin fa-pulse" />
                                        ) : null}
                                        Gem
                                      </Button>
                                    </Form>
                                  );
                                }}
                              />
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Container>
                  </>
                );
              }}
            </MutationData>
          );
        }}
      </NotifyContext.Consumer>
    );
  }
}

export default ResetPassword;
