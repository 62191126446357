import React from "react";
import styles from "./style.module.css";

const Spinner = () => (
  <div className={styles.spinner}>
    <div className={styles.cube1} />
    <div className={styles.cube2} />
  </div>
);

export default Spinner;
