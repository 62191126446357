import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Login from './views/Login/';
import SignUp from './views/SignUp/';
import CustomerFillForm from './views/CustomerFillForm/';
import { getToken } from './helper';
import './App.css';
import gql from 'graphql-tag';
import Dashboard from './Dashboard';
import Spinner from './components/UI/Spinner';
import { clearToken } from './helper';
import { AppContext } from './commen/AppContext';
import { NotificationAlertRoot } from './commen/NotifyContext';
import ResetPassword from './views/ResetPassword';

export const activeColor = 'primary';

export const ME = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
    }
  }
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      initialized: getToken() ? false : true
    };
  }

  componentWillMount() {
    if (!this.state.initialized) {
      if (getToken()) {
        this.me().then(() => {
          this.setState({ initialized: true });
        });
        return;
      }
      this.setState({ initialized: true });
    }
  }

  setUser = user => {
    this.setState({ user: user.me });
  };

  logout = async () => {
    const { client } = this.props;
    await client.resetStore().then(() => {
      client.clearStore();
    });
    clearToken();
    this.setState({ user: null });
    this.props.history.push('/');
  };

  me = async () => {
    const { client } = this.props;
    try {
      const res = await client.query({
        query: ME,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      });
      if (res && res.data && res.data.me) {
        this.setState({ user: res.data.me || null });
      }
    } catch (err) {
      clearToken();
    }
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          logout: this.logout,
          setUser: this.setUser,
          user: this.state.user
        }}
      >
        <main>
          {this.state.initialized ? (
            <AppContext.Consumer>
              {value => {
                if (value.user) {
                  return (
                    <Dashboard logout={value.logout} user={this.state.user} />
                  );
                } else {
                  return (
                    <>
                      <NotificationAlertRoot />
                      <div data={activeColor}>
                        <div className="content">
                          <Switch>
                            <Route
                              path="/resetPassword"
                              component={ResetPassword}
                            />
                            <Route
                              path="/customerFillForm"
                              component={CustomerFillForm}
                            />
                            <Route path="/sign-up" component={SignUp} />
                            <Route path="/" component={Login} />
                          </Switch>
                        </div>
                      </div>
                    </>
                  );
                }
              }}
            </AppContext.Consumer>
          ) : (
            <Spinner />
          )}
        </main>
      </AppContext.Provider>
    );
  }
}

export default withRouter(App);
